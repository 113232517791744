import { Subscription, SubscriptionCreate, SubscriptionUpdate } from '@kibsi/ks-realtime-data-types'
import { AxiosRequestConfig } from 'axios'
import { KibsiClientParams } from './base'
import { KibsiCrudClient } from './crud-base'

export class SubscriptionKibsiClient extends KibsiCrudClient {
    constructor(params: KibsiClientParams) {
        super(params, 'realtime-data')
    }

    list(deploymentId: string, requestConfig?: AxiosRequestConfig): Promise<Subscription[]> {
        return this.get(`/deployments/${deploymentId}/subscriptions`, requestConfig)
    }

    read(entityId: string, requestConfig?: AxiosRequestConfig): Promise<Subscription> {
        return this.get(`/subscriptions/${entityId}`, requestConfig)
    }

    create(entity: SubscriptionCreate, requestConfig?: AxiosRequestConfig): Promise<Subscription> {
        return this.post('/subscriptions', entity, requestConfig)
    }

    update(entity: SubscriptionUpdate, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/subscriptions/${entity.subscriptionId}`, entity, requestConfig)
    }

    delete(entityId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/subscriptions/${entityId}`, requestConfig)
    }
}
