import { action, makeAutoObservable, runInAction } from 'mobx'
import { DateTime } from 'luxon'
import type { ItemType as ItemTypeDto } from '@kibsi/ks-application-types'
import { Action as ActionDto, KibsiEvent, KibsiEventDetailed } from '@kibsi/ks-event-types'
import { ARTItemState } from '@kibsi/common'
import { EventService } from 'service'
import { FromDto } from '../interfaces'
import { ItemAttributesValues } from '../app'
import { Deployment } from '../deployment'
import { EventAction } from './event.action'
import { EventActionGroup } from './event.action.group'
import { ItemEventMediaMetadata } from './event.media.metadata'

// in seconds
const MinVisibility = 15

export type EventDto = KibsiEvent | KibsiEventDetailed

export class Event implements FromDto<EventDto> {
    private actionGroups: { [key: string]: EventActionGroup } = {}
    private eventAttrs?: ItemAttributesValues
    private sourceStartAttrs?: ItemAttributesValues
    private sourceEndAttrs?: ItemAttributesValues
    private visible = true

    readonly id: string
    readonly itemType: ItemTypeDto
    readonly sourceType?: ItemTypeDto

    constructor(private dto: EventDto, readonly deployment: Deployment, private service: EventService) {
        this.id = dto.id
        this.itemType = this.deployment.getItemTypeById(dto.itemType) as ItemTypeDto
        this.sourceType = this.deployment.getItemTypeById(this.itemType?.event?.sourceItemId ?? '')

        this.fromDto(dto)

        makeAutoObservable(this)
    }

    get deploymentId(): string {
        return this.deployment.deploymentId
    }

    get itemTypeId(): string {
        return this.dto.itemType
    }

    get startDate(): DateTime {
        const { deploymentStatusTime } = this.deployment
        const { endTime, startTime } = this.dto

        if (!startTime) {
            const time = Math.min(
                ...([endTime, deploymentStatusTime?.valueOf() ?? undefined].filter(Boolean) as number[]),
            )

            if (Number.isFinite(time)) {
                return DateTime.fromMillis(time)
            }

            if (startTime === undefined) {
                return DateTime.now()
            }
        }

        return DateTime.fromMillis(startTime)
    }

    get endDate(): DateTime | undefined {
        const { endTime } = this.dto

        if (isEndTime(endTime)) {
            return DateTime.fromMillis(endTime)
        }

        return undefined
    }

    get endActive(): DateTime | undefined {
        const { endTime } = this.dto

        if (isEndTime(endTime)) {
            return DateTime.fromMillis(endTime).plus({ seconds: MinVisibility })
        }

        return undefined
    }

    get eventAttributes(): ItemAttributesValues | undefined {
        return this.eventAttrs
    }

    get actionsTaken(): ActionDto[] {
        return this.details?.actionsTaken ?? []
    }

    get actions(): EventActionGroup[] {
        return Object.values(this.actionGroups)
    }

    get sourceTypeId(): string | undefined {
        return this.sourceType?.id
    }

    get sourceId(): string {
        return this.dto.sourceId
    }

    get sourceName(): string | undefined {
        return (this.sourceStartAttributes?.displayAttribute as string) ?? this.sourceType?.name
    }

    get shortSourceId(): string {
        return this.sourceId.slice(-4)
    }

    get sourceStartAttributes(): ItemAttributesValues | undefined {
        return this.sourceStartAttrs
    }

    get sourceEndAttributes(): ItemAttributesValues | undefined {
        return this.sourceEndAttrs
    }

    get hasMedia(): boolean {
        return this.details?.hasMedia ?? false
    }

    get isEnded(): boolean {
        return this.endDate !== undefined
    }

    get isActive(): boolean {
        return !this.isEnded
    }

    get isVisible(): boolean {
        return this.visible
    }

    get flagged(): boolean {
        return this.dto.flagged
    }

    async getLaunchId(): Promise<string> {
        if (!this.details) {
            await this.getDetailed()
        }

        return this.details?.launchId as string
    }

    async getDetailed(): Promise<void> {
        const dto = await this.service.get(this.deploymentId, this.id)

        this.fromDto(dto)
    }

    async updateFlagged(value: boolean): Promise<void> {
        return this.service.setEventFlagged(this.deploymentId, this.id, value).then(
            action(() => {
                this.dto.flagged = value
            }),
        )
    }

    async getMediaMetadata(): Promise<ItemEventMediaMetadata[]> {
        const result = await this.service.getMediaMetadata(this.deploymentId, this.id)

        return result.map((a) => new ItemEventMediaMetadata(a))
    }

    fromDto(dto: EventDto): void {
        this.dto = {
            ...this.dto,
            ...dto,
            id: this.id,
        }

        this.parseActions()

        const { eventState, sourceStartState, sourceEndState, sourceType, endActive } = this

        if (eventState) {
            this.eventAttrs = new ItemAttributesValues(eventState, this.itemType)
        }

        if (sourceStartState) {
            this.sourceStartAttrs = new ItemAttributesValues(sourceStartState, sourceType)
        }

        if (sourceEndState) {
            this.sourceEndAttrs = new ItemAttributesValues(sourceEndState, sourceType)
        }

        if (endActive) {
            const end = endActive.valueOf()

            if (end < Date.now()) {
                this.visible = false
            } else {
                setTimeout(() => {
                    runInAction(() => {
                        this.visible = false
                    })
                }, end - Date.now())
            }
        } else {
            this.visible = true
        }
    }

    private get details(): KibsiEventDetailed | undefined {
        return ifDetail(this.dto)
    }

    private get eventState(): ARTItemState | undefined {
        return this.details?.eventAttributes as ARTItemState
    }

    private get sourceStartState(): ARTItemState | undefined {
        return this.details?.startSourceState as ARTItemState
    }

    private get sourceEndState(): ARTItemState | undefined {
        return this.details?.endSourceState as ARTItemState
    }

    private parseActions(): void {
        this.actionGroups = {}

        this.actionsTaken.forEach((actionDto: ActionDto) => {
            const { trigger, definitionId } = actionDto
            const eAction = new EventAction(actionDto)

            if (!this.actionGroups[definitionId]) {
                this.actionGroups[definitionId] = new EventActionGroup(definitionId)
            }

            const group: EventActionGroup = this.actionGroups[definitionId]
            if (trigger === 'start') {
                group.start = eAction
            } else if (trigger === 'end') {
                group.end = eAction
            } else if (eAction.interval) {
                group.intervals[eAction.interval] = eAction
            }
        })
    }
}

function isDetail(dto: EventDto): dto is KibsiEventDetailed {
    return 'launchId' in dto && Boolean(dto.launchId)
}

function ifDetail(dto: EventDto): KibsiEventDetailed | undefined {
    if (isDetail(dto)) {
        return dto
    }

    return undefined
}

function isEndTime(time?: number): time is number {
    return time !== undefined && time >= 0
}
