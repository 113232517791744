import {
    Deployment,
    DeploymentCreate,
    DeploymentCriteria,
    DeploymentEventCriteria,
    DeploymentStop,
    DeploymentUpdate,
    LaunchApi,
    MultiLaunchResult,
    VdbEndpoint,
} from '@kibsi/ks-deployment-types'
import { AxiosRequestConfig } from 'axios'
import { PaginationRequest, PaginationResponse } from 'types/pagination'
import { KibsiClient, KibsiClientParams } from './base'

export class DeploymentKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, 'deployment')
    }

    list(
        paginationRequest?: PaginationRequest<DeploymentCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Deployment>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { siteId, versionId, appId, ids, includeDefinition } = criteria ?? {}

        return this.get(`/deployments`, {
            ...requestConfig,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            params: {
                ...requestConfig?.params,
                continuationToken,
                pageSize,
                sort,
                siteId,
                versionId,
                appId,
                ids: ids?.join(','),
                includeDefinition,
            },
        })
    }

    read(entityId: string, requestConfig?: AxiosRequestConfig): Promise<Deployment> {
        return this.get(`/deployments/${entityId}`, requestConfig)
    }

    create(entity: DeploymentCreate, requestConfig?: AxiosRequestConfig): Promise<Deployment> {
        return this.post('/deployments', entity, requestConfig)
    }

    update(entity: DeploymentUpdate, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/deployments/${entity.deploymentId}`, entity, requestConfig)
    }

    delete(deploymentId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/deployments/${deploymentId}`, requestConfig)
    }

    launch(deploymentId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/deployments/${deploymentId}/launch`, undefined, requestConfig)
    }

    stop(deploymentId: string, deploymentStop?: DeploymentStop, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/deployments/${deploymentId}/stop`, deploymentStop, requestConfig)
    }

    restart(deploymentId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/deployments/${deploymentId}/restart`, undefined, requestConfig)
    }

    resumeSchedule(deploymentId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/deployments/${deploymentId}/resume-schedule`, undefined, requestConfig)
    }

    discardDraft(deploymentId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/deployments/${deploymentId}/draft`, requestConfig)
    }

    vdbEndpoint(deploymentId: string, requestConfig?: AxiosRequestConfig): Promise<VdbEndpoint> {
        return this.get(`/deployments/${deploymentId}/vdb-endpoint`, requestConfig)
    }

    getLaunch(launchId: string, requestConfig?: AxiosRequestConfig): Promise<LaunchApi> {
        return this.get(`/launch/${launchId}`, requestConfig)
    }

    upgradeAndLaunch(
        toVersionId: string,
        deploymentIds: string[],
        requestConfig?: AxiosRequestConfig,
    ): Promise<MultiLaunchResult[]> {
        return this.post(`/deployments/upgrade/${toVersionId}/launch`, { deploymentIds }, requestConfig)
    }

    listEvents(
        deploymentId: string,
        paginationRequest?: PaginationRequest<DeploymentEventCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Deployment>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { action } = criteria ?? {}

        return this.get(`/deployments/${deploymentId}/deployment-events`, {
            ...requestConfig,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            params: {
                ...requestConfig?.params,
                continuationToken,
                pageSize,
                sort,
                action,
            },
        })
    }
}
