import { AttributeTypeDef } from '@kibsi/ks-application-types'
import { Primitives } from 'models'
import { AttributeValue } from 'models/snapshot'
import { normalizeAttributeValue } from './normalizeAttributeValue'

export const normalizeAttributeNameValue = (attrDef: AttributeTypeDef, attrValue: Primitives): AttributeValue => {
    const { id, name } = attrDef

    return {
        id,
        name: name || id,
        value: normalizeAttributeValue(attrValue),
    }
}
