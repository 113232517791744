import { AxiosRequestConfig } from 'axios'
import { ResourceTagCreate, ResourceType, Tag, TagCreate, TaggedResource } from '@kibsi/ks-search-tags-types'
import { PaginationRequest, PaginationResponse } from 'types'
import { KibsiClient, KibsiClientParams } from './base'

export class TagKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, 'search-tags')
    }

    listTags(
        paginationRequest?: PaginationRequest,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Tag>> {
        const { continuationToken, pageSize } = paginationRequest || {}
        const request: AxiosRequestConfig = requestConfig ? { ...requestConfig } : {}

        request.params = {
            ...requestConfig?.params,
            continuationToken,
            pageSize,
        }

        return this.get('/tags', request)
    }

    createTag(tag: TagCreate, requestConfig?: AxiosRequestConfig): Promise<Tag> {
        return this.post('/tags', tag, requestConfig)
    }

    getTag(tagId: string, requestConfig?: AxiosRequestConfig): Promise<Tag> {
        return this.get(`tags/${tagId}`, requestConfig)
    }

    deleteTag(tagId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`tags/${tagId}`, requestConfig)
    }

    /**
     *
     * @param tagIds - if supplied will limit resources to those that match 1 or more of supplied tagIds
     * @param resourceIds - will filter resources to only return resources that match resourceIds.
     * @param resourceType - will filter return resources by resourceType
     * @param requestConfig - axios request config
     * @returns - TaggedResources that match the supplied criteria
     */
    listResources(
        tagIds: string[],
        resourceIds: string[],
        resourceType?: ResourceType,
        requestConfig?: AxiosRequestConfig,
    ): Promise<TaggedResource[]> {
        const strTagIds = tagIds.length > 0 ? tagIds.join(',') : undefined
        const strResourceIds = resourceIds.length > 0 ? resourceIds.join(',') : undefined
        return this.get(`/resources`, {
            params: { tagId: strTagIds, resourceId: strResourceIds, resourceType },
            ...requestConfig,
        })
    }

    deleteResource(resourceId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/resources/${resourceId}`, requestConfig)
    }

    tagResource(resource: ResourceTagCreate, requestConfig?: AxiosRequestConfig): Promise<void> {
        const { resourceId } = resource
        return this.put(`resources/${resourceId}/tags`, resource, requestConfig)
    }

    removeTagFromResource(tagId: string, resourceId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`resources/${resourceId}/tags/${tagId}`, requestConfig)
    }
}
