import { AxiosRequestConfig } from 'axios'
import { Stream, StreamCreate, StreamUpdate, UploadUrl, StreamCriteria } from '@kibsi/ks-camera-types'
import { PaginationRequest, PaginationResponse } from 'types/pagination'
import { KibsiClient, KibsiClientParams } from './base'

export class StreamKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, 'camera')
    }

    listStreamsForSite(
        siteId: string,
        paginationRequest?: PaginationRequest<StreamCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Stream>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { ids, q } = criteria ?? {}

        const request: AxiosRequestConfig = requestConfig ? { ...requestConfig } : {}

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        request.params = {
            ...requestConfig?.params,
            continuationToken,
            pageSize,
            sort,
            siteId,
            ids: ids?.join(','),
            q,
        }

        return this.get<PaginationResponse<Stream>>('streams', request)
    }

    createStream(stream: StreamCreate, requestConfig?: AxiosRequestConfig): Promise<Stream> {
        return this.post<Stream, StreamCreate>('streams', stream, requestConfig)
    }

    getStream(streamId: string, requestConfig?: AxiosRequestConfig): Promise<Stream> {
        return this.get<Stream>(`streams/${streamId}`, requestConfig)
    }

    updateStream(streamId: string, update: StreamUpdate, requestConfig?: AxiosRequestConfig): Promise<Stream> {
        return this.put<Stream, StreamUpdate>(`streams/${streamId}`, update, requestConfig)
    }

    deleteStream(streamId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`streams/${streamId}`, requestConfig)
    }

    saveSnapshot(streamId: string, imageFile: Blob, requestConfig?: AxiosRequestConfig): Promise<void> {
        const request: AxiosRequestConfig = requestConfig ? { ...requestConfig } : {}

        request.headers = {
            ...request?.headers,
            'Content-Type': imageFile.type,
        }

        return this.post<void, Blob>(`streams/${streamId}/snapshot`, imageFile, request)
    }

    getSnapshot(streamId: string, requestConfig?: AxiosRequestConfig): Promise<ArrayBuffer> {
        const request: AxiosRequestConfig = { ...requestConfig, responseType: 'arraybuffer' }

        return this.get<ArrayBuffer>(`streams/${streamId}/snapshot`, request)
    }

    getLiveSnapshot(streamId: string, requestConfig?: AxiosRequestConfig): Promise<ArrayBuffer> {
        const request: AxiosRequestConfig = { ...requestConfig, responseType: 'arraybuffer' }

        return this.get<ArrayBuffer>(`streams/${streamId}/snapshot/live`, request)
    }

    getStreamFileUploadUrl(
        streamId: string,
        contentType: string,
        requestConfig?: AxiosRequestConfig,
    ): Promise<UploadUrl> {
        return this.get(`/streams/${streamId}/upload`, {
            params: {
                contentType,
            },
            ...requestConfig,
        })
    }

    downloadStreamFile(streamId: string, redirect: boolean, requestConfig?: AxiosRequestConfig): Promise<unknown> {
        return this.get(`/streams/${streamId}/download`, {
            params: {
                redirect,
            },
            ...requestConfig,
        })
    }
}
