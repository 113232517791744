import {
    Detector,
    DetectorCreate,
    DetectorCriteria,
    Model,
    ModelCreate,
    ModelUpdate,
    ModelCriteria,
    DetectorUpdate,
    DetectorsAndModels,
    UploadUrl,
    DetectorMapping,
    DetectorMappings,
} from '@kibsi/ks-application-types'
import { AxiosRequestConfig } from 'axios'
import { PaginationRequest, PaginationResponse } from 'types/pagination'
import { KibsiClient, KibsiClientParams } from './base'

const SERVICE = 'application'

export class DetectorKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, SERVICE)
    }

    list(
        paginationRequest?: PaginationRequest<DetectorCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Detector>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { ids, usage, hasEnhancer } = criteria ?? {}
        return this.get('/detectors', {
            ...(paginationRequest && {
                params: { continuationToken, pageSize, sort, ids: ids?.join(','), hasEnhancer, usage },
            }),
            ...requestConfig,
        })
    }

    createDetector(entity: DetectorCreate, requestConfig?: AxiosRequestConfig): Promise<Detector> {
        return this.post('/detectors', entity, requestConfig)
    }

    getDetector(detectorId: string, requestConfig?: AxiosRequestConfig): Promise<Detector> {
        return this.get(`/detectors/${detectorId}`, requestConfig)
    }

    updateDetector(entity: DetectorUpdate, requestConfig?: AxiosRequestConfig): Promise<Detector> {
        return this.put(`/detectors/${entity.id}`, entity, requestConfig)
    }

    getByIds(ids: string[], includeModels: boolean, requestConfig?: AxiosRequestConfig): Promise<DetectorsAndModels> {
        return this.get('/detectors/byids', {
            ...requestConfig,
            params: { ids: ids.join(','), includeModels },
        })
    }

    deleteDetector(detectorId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/detectors/${detectorId}`, requestConfig)
    }

    listDetectorEnhancers(
        detectorId: string,
        paginationRequest?: PaginationRequest,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Detector>> {
        const { continuationToken, pageSize, sort } = paginationRequest || {}
        return this.get(`/detectors/${detectorId}/enhancers`, {
            ...requestConfig,
            params: { continuationToken, pageSize, sort },
        })
    }

    createDetectorEnhancer(detectorId: string, enhancerId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        const body: DetectorMapping = { detectorId: enhancerId }

        return this.post(`/detectors/${detectorId}/enhancers`, body, requestConfig)
    }

    addDetectorEnhancers(
        detectorId: string,
        enhancerIds: DetectorMappings,
        requestConfig?: AxiosRequestConfig,
    ): Promise<void> {
        return this.put(`/detectors/${detectorId}/enhancers`, enhancerIds, requestConfig)
    }

    removeDetectorEnhancer(detectorId: string, enhancerId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/detectors/${detectorId}/enhancers/${enhancerId}`, requestConfig)
    }

    listModels(
        paginationRequest?: PaginationRequest<ModelCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Model>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { ids } = criteria ?? {}
        return this.get('/models', {
            ...(paginationRequest && { params: { continuationToken, pageSize, sort, ids: ids?.join(',') } }),
            ...requestConfig,
        })
    }

    createModel(entity: ModelCreate, requestConfig?: AxiosRequestConfig): Promise<Model> {
        return this.post(`/models`, entity, requestConfig)
    }

    getModel(modelId: string, requestConfig?: AxiosRequestConfig): Promise<Model> {
        return this.get(`/models/${modelId}`, requestConfig)
    }

    updateModel(entity: ModelUpdate, requestConfig?: AxiosRequestConfig): Promise<Model> {
        return this.put(`/models/${entity.modelId}`, entity, requestConfig)
    }

    deleteModel(modelId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/models/${modelId}`, requestConfig)
    }

    getDetectorsForModel(
        modelId: string,
        paginationRequest?: PaginationRequest,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Detector>> {
        const { continuationToken, pageSize, sort } = paginationRequest || {}
        return this.get(`/models/${modelId}/detectors`, {
            ...requestConfig,
            params: { continuationToken, pageSize, sort },
        })
    }

    getModelUploadUrl(modelId: string, requestConfig?: AxiosRequestConfig): Promise<UploadUrl> {
        return this.get(`/models/${modelId}/upload`, requestConfig)
    }

    downloadModelFile(modelId: string, redirect: boolean, requestConfig?: AxiosRequestConfig): Promise<unknown> {
        return this.get(`/models/${modelId}/download`, {
            params: {
                redirect,
            },
            ...requestConfig,
        })
    }
}
