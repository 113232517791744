import { AttributeRelationTypeDef, AttributeTypeDef, RelationDef } from '../generated/types'

import { isRelationshipAttr } from './relationship.guard'

/** RelationDef narrowed to proximity */
export type ProximityRelationDef = RelationDef & Required<Pick<RelationDef, 'proximity'>>

export function isProximityRelationDef(relationDef?: RelationDef): relationDef is ProximityRelationDef {
    return relationDef?.relationType === 'proximity'
}

/** Attribute narrowed to proximity relationship */
export type ProximityRelationshipAttr = Omit<AttributeTypeDef, 'value'> & {
    value: Omit<AttributeRelationTypeDef, 'relation'> & {
        relation: ProximityRelationDef
    }
}

export function isProximityRelationshipAttr(attr?: AttributeTypeDef): attr is ProximityRelationshipAttr {
    return isRelationshipAttr(attr) && isProximityRelationDef(attr.value.relation)
}
