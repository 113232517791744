import { AxiosRequestConfig } from 'axios'
import { Logger, ErrorHandler } from '../types'
import { KibsiClientParams, KibsiClient, KIBSI_REQUEST_ID_HEADER } from './base'
import { KibsiCrudClient } from './crud-base'
import { DeploymentKibsiClient } from './deployment'
import { EventKibsiClient } from './event'
import { SiteKibsiClient } from './site'
import { SubscriptionKibsiClient } from './subscription'
import { TimeKibsiClient } from './time'
import { StreamKibsiClient } from './stream'
import { TenantKibsiClient } from './tenant'
import { UserKibsiClient } from './user'
import { IntegrationKibsiClient } from './integration'
import { ApplicationKibsiClient } from './application'
import { DetectorKibsiClient } from './detector'
import { RealtimeDataKibsiClient } from './realtime-data'
import { HistoryKibsiClient } from './history'
import { EntitlementKibsiClient } from './entitlement'
import { EdgeDeviceKibsiClient } from './edge-device'
import { FloorPlanKibsiClient } from './floor-plan'
import { DashboardKibsiClient } from './dashboard'
import { TagKibsiClient } from './tag'

export {
    DeploymentKibsiClient,
    EventKibsiClient,
    KibsiClient,
    KibsiClientParams,
    KibsiCrudClient,
    SubscriptionKibsiClient,
    TimeKibsiClient,
    StreamKibsiClient,
    ApplicationKibsiClient,
    SiteKibsiClient,
    DetectorKibsiClient,
    RealtimeDataKibsiClient,
    HistoryKibsiClient,
    UserKibsiClient,
    EntitlementKibsiClient,
    TenantKibsiClient,
    EdgeDeviceKibsiClient,
    FloorPlanKibsiClient,
    DashboardKibsiClient,
    IntegrationKibsiClient,
    TagKibsiClient,
    KIBSI_REQUEST_ID_HEADER,
}

export interface IKibsiClientSDK {
    tenants: () => TenantKibsiClient
    sites: () => SiteKibsiClient
    users: () => UserKibsiClient
    integrations: () => IntegrationKibsiClient
    deployments: () => DeploymentKibsiClient
    events: () => EventKibsiClient
    subscriptions: () => SubscriptionKibsiClient
    streams: () => StreamKibsiClient
    applications: () => ApplicationKibsiClient
    detectors: () => DetectorKibsiClient
    realtimeData: () => RealtimeDataKibsiClient
    history: () => HistoryKibsiClient
    entitlement: () => EntitlementKibsiClient
    edgeDevice: () => EdgeDeviceKibsiClient
    floorPlan: () => FloorPlanKibsiClient
    dashboard: () => DashboardKibsiClient
    tag: () => TagKibsiClient
}

export type KibsiClientSDKParams = {
    /**
     * API base path template with {service} placeholder. Defaults to public production Kibsi API:
     */
    basePathTemplate?: string

    /**
     * Provider a logger for the SDK to produce log output.
     */
    logger?: Logger

    /**
     * Custom error handler
     */
    errorHandler?: ErrorHandler

    /**
     * Optional config to apply to the axios instance.
     * Use to populate Authorization headers, etc.
     * Will be invoked for each api request.
     * Additionally, each API call optionally takes requestConfig that can supersede these defaults.
     */
    axiosConfigProvider?: () => Promise<AxiosRequestConfig>
}

const PROD_PATH_TEMPLATE = 'https://api.kibsi.com/{service}'

type IKibsiClient<T> = {
    new (params: KibsiClientParams): T
}

/**
 * SDK exposing entity clients.
 */
export class KibsiClientSDK {
    private clientParams: KibsiClientParams

    private clients: { [key: string]: unknown } = {}

    constructor(sdkParams: KibsiClientSDKParams) {
        this.clientParams = {
            basePathTemplate: PROD_PATH_TEMPLATE,
            ...sdkParams,
        }
        if (this.clientParams.basePathTemplate.indexOf('{service}') === -1) {
            throw new Error('basePathTemplate must include {service} placeholder.')
        }
    }

    static tokenAuthRequestConfig(token: { access_token: string }): AxiosRequestConfig {
        return {
            headers: {
                Authorization: `Bearer ${token.access_token}`,
            },
        }
    }

    protected cacheClient<T, C extends IKibsiClient<T>>(key: string, Client: C): T {
        if (!this.clients[key]) {
            this.clientParams.logger?.debug(`Initializing client for ${key}.`)
            this.clients[key] = new Client(this.clientParams)
        }
        return this.clients[key] as T
    }

    protected client<C extends KibsiClient>(factory: (params: KibsiClientParams) => C): C {
        return factory({ ...this.clientParams })
    }

    tenants(): TenantKibsiClient {
        return this.cacheClient('tenants', TenantKibsiClient)
    }

    sites(): SiteKibsiClient {
        return this.cacheClient('sites', SiteKibsiClient)
    }

    users(): UserKibsiClient {
        return this.cacheClient('users', UserKibsiClient)
    }

    integrations(): IntegrationKibsiClient {
        return this.cacheClient('integrations', IntegrationKibsiClient)
    }

    deployments(): DeploymentKibsiClient {
        return this.cacheClient('deployments', DeploymentKibsiClient)
    }

    events(): EventKibsiClient {
        return this.cacheClient('events', EventKibsiClient)
    }

    subscriptions(): SubscriptionKibsiClient {
        return this.cacheClient('subscriptions', SubscriptionKibsiClient)
    }

    time(): TimeKibsiClient {
        return this.cacheClient('time', TimeKibsiClient)
    }

    streams(): StreamKibsiClient {
        return this.cacheClient('streams', StreamKibsiClient)
    }

    detectors(): DetectorKibsiClient {
        return this.cacheClient('detectors', DetectorKibsiClient)
    }

    applications(): ApplicationKibsiClient {
        return this.cacheClient('applications', ApplicationKibsiClient)
    }

    realtimeData(): RealtimeDataKibsiClient {
        return this.cacheClient('realtime-data', RealtimeDataKibsiClient)
    }

    history(): HistoryKibsiClient {
        return this.cacheClient('history', HistoryKibsiClient)
    }

    entitlement(): EntitlementKibsiClient {
        return this.cacheClient('entitlement', EntitlementKibsiClient)
    }

    edgeDevice(): EdgeDeviceKibsiClient {
        return this.cacheClient('edgeDevice', EdgeDeviceKibsiClient)
    }

    floorPlan(): FloorPlanKibsiClient {
        return this.cacheClient('floorPlan', FloorPlanKibsiClient)
    }

    dashboard(): DashboardKibsiClient {
        return this.cacheClient('dashboard', DashboardKibsiClient)
    }

    tag(): TagKibsiClient {
        return this.cacheClient('tag', TagKibsiClient)
    }
}
