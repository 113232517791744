{
  "kibsi": "Kibsi",
  "alert": {
    "error": "Error",
    "notDefined": "{{label}} not defined",
    "notFound": "{{label}} not found",
    "contactToUpgrade": "Upgrade your account to use {{label}} by contacting Kibsi",
    "contactToEnable": "Upgrade your account to enable this function by contacting Kibsi",
    "increaseLimit": "Please contact Kibsi if you would like to increase your limit",
    "limitExceeded": "Limit exceeded",
    "unknown": "Unknown"
  },
  "navigation": {
    "dashboard": "Dashboard",
    "applications": "Applications",
    "sites": "Sites",
    "models": "Models",
    "detectors": "Detectors",
    "account": "Account",
    "logout": "Logout"
  },
  "dashboards": "Dashboards",
  "dashboard": {
    "title": "Kibsi Dashboard",
    "greeting": "Welcome to the Dashboard",
    "label": "dashboard",
    "splash": {
      "heading": "You do not have any dashboards yet.",
      "altImage": "Add a new floor dashboard",
      "title": "Create your first dashboard.",
      "message": "Create a floor plan and heat map to visualize the movement of detected items in your space."
    },
    "create": {
      "button": "Create dashboard",
      "title": "New dashboard",
      "description": "Name and configure your dashboard",
      "application": "Application"
    },
    "edit": {
      "title": "Edit dashboard",
      "description": "Make changes to your dashboard"
    },
    "name": {
      "rule": "Dashboard name cannot be empty"
    },
    "description": {
      "placeholder": "Describe your dashboard"
    },
    "baseImage": {
      "title": "Base Image",
      "rule": "Please select a floor plan or stream"
    },
    "deployments": {
      "noStream": "This deployment’s stream is not included in the selected floor plan"
    },
    "application": {
      "title": "Application",
      "rule": "Please select an existing application"
    },
    "type": {
      "title": "Type",
      "heatmap": "Heatmap"
    },
    "display": {
      "title": "Display",
      "grid": "Grid",
      "metric": "Metric",
      "dwell": "Dwell",
      "count": "Count",
      "median": "Median",
      "average": "Average",
      "total": "Total",
      "small": "S",
      "medium": "M",
      "large": "L"
    },
    "quicksight": {
      "error": {
        "url": "Cannot display QuickSight dashboard"
      },
      "details": {
        "filters": "Filters",
        "application": "Application",
        "general": "General"
      }
    }
  },
  "action": {
    "close": "Close",
    "create": "Create",
    "save": "Save",
    "saveDeploy": "Save & Deploy",
    "cancel": "Cancel",
    "edit": "Edit",
    "delete": "Delete",
    "searchEllipsis": "Search...",
    "notFound": "Not found",
    "next": "Next",
    "back": "Back",
    "previous": "Previous",
    "discard": "Discard",
    "skip": "Skip",
    "continue": "Continue",
    "undo": "Undo",
    "redo": "Redo",
    "reset": "Reset",
    "refresh": "Refresh",
    "clear": "Clear",
    "download": "Download",
    "disable": "Disable",
    "enable": "Enable",
    "selectAll": "Select all",
    "removeAll": "Remove all",
    "remove": "Remove",
    "yes": "Yes",
    "no": "No",
    "ok": "OK",
    "showMedia": "Show Media",
    "copy": "Copy",
    "makeCopy": "Make a copy",
    "restore": "Restore",
    "retry": "Retry",
    "addFlag": "Add flag",
    "removeFlag": "Remove flag",
    "add": "Add"
  },
  "common": {
    "createdBy": "Created by {{name}}",
    "published": "Published",
    "keyValuePair": "{{key}}: {{value}}",
    "details": "Details",
    "required": "Required",
    "name": "Name",
    "description": "Description",
    "active": "Active",
    "inactive": "Inactive",
    "items": "items",
    "none": "None",
    "live": "live"
  },
  "delete": {
    "title": {
      "default": "Delete {{name}}?",
      "orphanCascade": "{{name}}'s dependents will be impacted. Continue?",
      "blocked": "{{name}} cannot be deleted"
    },
    "message": {
      "cascade": "The following objects will also be deleted: {{dep}}",
      "orphaned": "The following objects may require modification before they work properly: {{dep}}",
      "blocked": "The following objects must be manually stopped or deleted before continuing: {{dep}}",
      "generic": "Are you sure you would like to delete {{ item }}? This cannot be undone"
    },
    "site": "This will delete all streams, deployments and data and cannot be undone.",
    "deployment": {
      "stopped": "This will delete the deployment and associated data and cannot be undone",
      "running": "The deployment must be stopped before deleting it"
    }
  },
  "item": {
    "this": "this {{ value }}",
    "your": "your {{ value }}"
  },
  "copy": {
    "title": "Copy {{ name }}?",
    "message": {
      "app": "This will make a copy of the application",
      "deployment": "This will make a copy of the deployment"
    },
    "name": "{{ name }} copy"
  },
  "applications": "applications",
  "application": {
    "label": "application",
    "title": "Kibsi Applications",
    "createApplication": "Create Application",
    "applicationImageAlt": "Application Image",
    "unPublishedTooltip": "This application cannot be selected because it has not been published.",
    "goTo": "Go to application.",
    "create": {
      "title": "New Application",
      "description": "Enter your basic information",
      "rules": {
        "name": "Application name cannot be empty"
      }
    },
    "lastEdited": "Last edited",
    "detectorsInUse": "{{inuse}}/{{max}} Detectors",
    "detectorLimit_one": "Your application has a limit of {{count}} detector. You will need to be within your limit to publish your application.",
    "detectorLimit_other": "Your application has a limit of {{count}} detectors. You will need to be within your limit to publish your application.",
    "numDetectors_one": "{{count}} detector",
    "numDetectors_other": "{{count}} detectors",
    "version": {
      "name": "Version {{version}}",
      "disable": "Disabling a version makes it impossible to deploy from later",
      "view": "View version on the canvas",
      "restore": {
        "title": "Viewing version {{version}}",
        "description": "Choose restore to revert to this version.",
        "success": "Version restored"
      }
    },
    "versions": {
      "title": "Versions",
      "publishNew": "Publish New",
      "hide": "Hide",
      "viewOlder": "View older",
      "create": {
        "dialogTitle": "Add to version history",
        "input": {
          "name": "Title",
          "description": "Notes",
          "placeholder": {
            "name": "Give it a title",
            "description": "Describe what changed"
          }
        },
        "rules": {
          "name": "Version name cannot be empty"
        }
      },
      "deploy": {
        "dialogTitle": "Deploy version {{versionNumber}}",
        "title": "Deploy your application by choosing one of the options below.",
        "subtitleNew": "Create a new deployment",
        "subtitleExisting": "Update an existing deployment",
        "notSelected": "Please select a Site or Existing Deployment",
        "confirmManual": "Manually update this deployment?",
        "confirmManualBody": "{{name}} deployment is not elligble to be automatically updated, and must be manually configured. Continuing will remove all previously selected deployments. Do you wish to continue?",
        "selectUpgradable": "Select eligible",
        "updatingDeploymentsTitle": "Updating Deployments",
        "updateDeploymentsBody": "The selected deployments are being updated. Check each deployment for detailed status.",
        "infoToolTip": "Cannot be automatically updated"
      }
    },
    "builder": {
      "addStaticItem": {
        "name": "Static",
        "description": "A stationary item that is permanently in camera view. (e.g. table, parking spot)"
      },
      "addDetectedItem": {
        "name": "Detected",
        "description": "An item that is automatically detected and tracked within the camea view. (e.g. person, car)"
      },
      "addEventItem": {
        "name": "Event",
        "description": " An item that is created based on a start and end condition. (e.g. safety violation, occupancy exceeded)"
      },
      "instruction": {
        "title": "Let’s start to build your application",
        "description": " Applications can be made out of static and detected items as well as events. Start by clicking on a tile below. Additional item types can be added with the button in the top left corner."
      },
      "description": {
        "placeholder": "Describe your application"
      },
      "region": "Region",
      "autoAddRegion": "Automatically adds a region attribute to the static item"
    },
    "noApplicationsTitle": "You do not have any applications yet.",
    "noApplicationsMessage": "When you create an application you will be able to view and edit them all in one spot. Click on the plus on the top left to create a new application or start one from here.",
    "itemType": {
      "type": "Type",
      "static": {
        "createTitle": "Add a new static item",
        "createDescription": "A static item type is used when it is undesirable or not possible to have the item dynamically created and removed by the system. Specific items of this type are created during application deployment.",
        "rules": {
          "name": "Static item name cannot be empty"
        }
      },
      "detected": {
        "createTitle": "Add a new detected item",
        "editTitle": "Edit detected item",
        "createDescription": "A detected item is created based on an observation from the configured detector. Items of this type are automatically created and removed based on configured rules.",
        "customConfidence": "Custom confidence",
        "confidenceDesc": "Confidence adjusts the sensitivity of the detector.",
        "enhancerTitle": "Select enhancers",
        "rules": {
          "name": "Detected item name cannot be empty",
          "detector": "Select a detector type",
          "options": "Detector options are invalid"
        },
        "label": "Detector",
        "options": {
          "tracking": {
            "title": "Tracking",
            "description": "Enables the movement tracking of objects within a frame.",
            "overlap": "Frame overlap",
            "overlapDescription": "Determines consecutive frames required in order to be considered the same object.",
            "delay": "Tracking delay",
            "delayDescription": "Adjusts the time between detection and tracking. Input values from 1 to 1000ms.",
            "delayStart": "Start",
            "delayEnd": "End"
          }
        },
        "relationships": {
          "proximity": {
            "description": "Override the previously configured application settings for relationship proximities.",
            "subDescription": "Define under which conditions a {{ itemA }} should count as having proximity with a {{ itemB }}",
            "regionDescription": "{{ itemType }} attribute: {{ region }}"
          }
        }
      },
      "event": {
        "createTitle": "New event",
        "editTitle": "Edit event",
        "createDescription": "An event is created when a condition defined on the Source becomes true. Optionally, a span event can remain active until an ending condition becomes true.",
        "condition": {
          "start": {
            "title": "Add a start condition",
            "label": "Start condition"
          },
          "end": {
            "title": "Add an end condition",
            "label": "End condition",
            "description": "End condition automatically set to “If start condition becomes false”"
          }
        },
        "input": {
          "sources": "Source"
        },
        "options": {
          "atBeginning": "At beginning",
          "atEnd": "At end",
          "entireTime": "Entire time",
          "onAction": "Triggered action",
          "saveImage": "Save image",
          "saveVideo": "Save video",
          "duration": "Duration",
          "timeBeforeStart": "Time before start",
          "timeBeforeEnd": "Time before end",
          "timeAfterEnding": "Time after ending",
          "description": "Choose one or more output options",
          "tooltip": {
            "timeSelectionRestricted": "Time selection restricted."
          }
        },
        "addEndCondition": "Add End Condition",
        "rules": {
          "name": "Event name cannot be empty",
          "source": "Select a Source"
        },
        "addNewProperty": "Add a new event attribute",
        "addNewAction": "Add a new event action",
        "type": {
          "label": "Event Type",
          "span": {
            "label": "Span",
            "description": "The event happens across a specific period of time."
          },
          "occurrence": {
            "label": "Occurrence",
            "description": "The event is a single moment in time."
          }
        }
      }
    },
    "attribute": {
      "name": {
        "label": "Attribute Name"
      },
      "target": {
        "label": "Target"
      },
      "header": "Attributes",
      "input": {
        "type": "Type",
        "default": "Default value",
        "choices": "Choices",
        "placeholder": {
          "number": "Enter number",
          "region": "Specify the region's value during application deployment",
          "text": "Enter text",
          "choice": "Enter choices separated by return key"
        }
      },
      "rules": {
        "name": "Attribute name cannot be empty"
      },
      "addNewTitle": "Add a new attribute",
      "suggestions": "Suggestions",
      "addManually": "Add manually",
      "enhancer": {
        "title": "Enhancer",
        "description": "Detect additional properties about this item",
        "addNewTitle": "Add an enhancer",
        "selectTitle": "Select an enhancer",
        "briefDescription": "Maecenas sed diam eget risus varius blandit sit amet non magna. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.",
        "emptyResults": "No compatible enhancers found",
        "input": {
          "type": "Type",
          "placeholder": {
            "name": "Enhancer name"
          },
          "rules": {
            "name": "Enhancer name cannot be empty",
            "enhancerId": "An enhancer must be selected"
          }
        },
        "options": {
          "includeConfidence": "Include confidence",
          "includeConfidenceDescription": "Include the enhancer's confidence score along with the value."
        }
      },
      "expression": {
        "title": "Expression",
        "addNewTitle": "Add an expression attribute",
        "formulaExpression": "Formula expression",
        "description": "Compute an attribute value using an expression",
        "briefDescription": "An expression uses existing data, through other attributes, relationships and functions, in order to compute a new value."
      },
      "stateMachine": {
        "addNewTitle": "Add a state machine",
        "editExistingTitle": "Edit a state machine",
        "title": "State machine",
        "description": "Track and persist states this item progresses through",
        "briefDescription": "A state machine allows you to define persistent states an item can be in as well as the transition rules between them.",
        "input": {
          "name": "State machine name",
          "editName": "Edit name",
          "addAState": "Add a state",
          "rules": {
            "addAState": "State name is required",
            "minimumStateCount": "Must have at least one state",
            "noDuplicateStateNames": "State name already exists"
          }
        },
        "options": {
          "setAsDefault": "Set as default"
        },
        "transitionName": "{{source}} to {{target}}",
        "addExpression": "Add Expression",
        "addAnExpression": "Add an expression",
        "editExpression": "Edit expression",
        "delete": {
          "title": "Delete {{name}}",
          "text": "You’re about to remove this state from your state machine. All transitions to and from this state will be removed."
        },
        "hint": {
          "beginWithTwoStates": "Create two or more states to begin",
          "buildExpression": "Connect two states to build an expression",
          "connectState": "Connect a state to {{stateName}}"
        }
      },
      "value": {
        "title": "Simple",
        "editTitle": "Edit attribute",
        "description": "Add text, number or boolean metadata to this item",
        "name": "Attribute Name",
        "boolean": "Boolean",
        "number": "Number",
        "text": "Text",
        "choice": "Choice"
      },
      "relationship": {
        "title": "Relationship",
        "relation": "Relation",
        "briefDescription": "Relationships correlate items from two types, in order to build a rich, contextual understanding within your application.",
        "addHeader": "Add a relationship to {{name}}",
        "editHeader": "Edit relationship to {{name}}",
        "description": "Define a relationship between this and another item type",
        "source": "Source",
        "target": "Target",
        "targets": "Targets",
        "noTargetSelected": "No target selected",
        "noAvailableItemTypes": "No available item types",
        "relationshipType": {
          "title": "Relationship Type",
          "expression": "Expression",
          "proximity": "Proximity"
        },
        "proximity": {
          "hint": "Select a region attribute to define proximity",
          "subHint": "If no region attribute exists, we will create one for you.",
          "noRegions": "A region attribute will be added to the selected target",
          "detectedRegion": "Item's location will be used",
          "region": "Region",
          "noRegionSelected": "No region selected",
          "defineRelationship": "Define the relationship",
          "switch": "Switch",
          "proximityType": {
            "title": "Proximity Type",
            "inside": "Inside",
            "near": "Near",
            "noneAvailable": "No available proximity types"
          },
          "evalPosition": "Evaluation position",
          "evalPositionOpts": {
            "center": "Center",
            "top": "Top",
            "right": "Right",
            "bottom": "Bottom",
            "left": "Left"
          },
          "noProximitySelected": "No proximity selected"
        }
      },
      "relationships": "Relationships",
      "region": {
        "addHeader": "Add new region",
        "editHeader": "Edit region",
        "title": "Region",
        "placeholder": "Attribute Name",
        "description": "Associate a named portion of the camera image",
        "briefDescription": "A region attribute associates a part of the camera image with the static item, enabling interactions and relationshps with items of this type. The region in the camera image will be selected while configuring the deployment of this application.",
        "value": "Specify the region's value during application deployment",
        "info": "Specify at deployment"
      },
      "display": {
        "title": "Display attribute",
        "noneSelected": "(Please select)",
        "requiresAttr": "Requires a text or number attribute"
      },
      "count": {
        "title": "Counter",
        "addTitle": "Add a new counter attribute",
        "editTitle": "Edit counter attribute",
        "description": "Count the number of an item",
        "briefDescription": "Use counters to track the number of items exist at any given time or over a configurable time interval",
        "filter": {
          "label": "Filter condition (optional)",
          "button": "Add a filter",
          "title": "Add a filter as an expression"
        },
        "condition": {
          "label": "Count condition",
          "button": "Add count condition",
          "title": "Add a condition as an expression"
        },
        "schedule": {
          "label": "Reset the counter",
          "mode": {
            "label": "Determine the number of items"
          },
          "daily": "daily",
          "hourly": "hourly",
          "weekly": "weekly",
          "atMinute": "at minute",
          "at": "at",
          "on": "on"
        },
        "window": {
          "none": {
            "label": "at any given time"
          },
          "sliding": {
            "label": "within a time interval"
          },
          "scheduled": {
            "label": "within a time frame specified by a schedule"
          },
          "condition": {
            "label": "while the specified condition is true"
          }
        },
        "duration": {
          "label": "Count the items in previous"
        },
        "info": {
          "target": "Target",
          "window": {
            "title": "Time Frame",
            "sliding": "{{value}} {{unit}}",
            "tumbling": {
              "daily": "Daily {{hour}}:{{min}} {{am}}",
              "hourly": "Hourly, {{min}}min past",
              "weekly": "Every {{day}}, {{hour}}:{{min}} {{am}}"
            },
            "none": "Any Time"
          },
          "filter": "Filter condition"
        }
      },
      "class": {
        "title": "Item class",
        "description": "Map the detector’s class determination into an attribute",
        "editTitle": "Edit Item class"
      },
      "location": {
        "title": "Location",
        "description": "Periodically samples and provides the items location (bounding box)",
        "editTitle": "Edit Location",
        "sampleRate": "Sample Rate"
      },
      "confidence": {
        "title": "Confidence",
        "description": "Map the detector’s confidence into an attribute",
        "addTitle": "Add detector's confidence attribute name",
        "editTitle": "Edit detector's confidence attribute name"
      },
      "delay": {
        "description": "Delay setting the attribute's value until it has stabilized for the specified duration."
      }
    },
    "eventOutputs": {
      "header": "Output",
      "image": "Image",
      "video": "Video"
    },
    "eventAction": {
      "action": "action",
      "header": "Actions",
      "addTitle": "New {{name}} action",
      "editTitle": "Edit {{name}} action",
      "options": {
        "repeat": {
          "label": "Repeat",
          "description": "The action is repeated if it is still in effect after the selected time span.",
          "interval": "Every {{value}}",
          "times": "{{value}} times",
          "unlimited": "Unlimited"
        }
      },
      "common": {
        "atStart": "At start",
        "atEnd": "At end",
        "atStartEnd": "At start and end",
        "sendAction": "Send action",
        "withEachAction": "With each action",
        "includeImage": "Include image"
      },
      "rules": {
        "triggerStartEnd": "Select when to send the action"
      },
      "email": {
        "title": "Email",
        "actionTitle": "Emails",
        "list": {
          "title": "Send an email",
          "description": "Send an email notification to one or more recipients"
        },
        "header": "Email",
        "email": {
          "label": "Recipient",
          "rules": {
            "required": "Email is required",
            "pattern": "Invalid Email Address"
          }
        },
        "emails": {
          "rules": {
            "required": "At least one email is required"
          }
        }
      },
      "sms": {
        "title": "Text Message",
        "actionTitle": "Text messages",
        "list": {
          "title": "Send a text message",
          "description": "Send an SMS text message notification"
        },
        "header": "Text message"
      },
      "webhook": {
        "title": "Webhook",
        "actionTitle": "API calls",
        "list": {
          "title": "Invoke a Webhook",
          "description": "Call a custom webhook API with event details"
        },
        "header": "Webhook",
        "url": {
          "label": "URL",
          "placeholder": "Copy URL here",
          "rules": {
            "required": "URL is required"
          }
        },
        "token": {
          "label": "Authorization token (optional)",
          "placeholder": "A token that will be passed in the authorization header"
        },
        "edge": {
          "label": "Invoke Webhook through an edge device, if configured"
        }
      }
    },
    "delete": {
      "message": "This will delete all published versions"
    },
    "edit": {
      "id": {
        "action": "Change ID",
        "title": "Change {{value}}'s ID",
        "label": "ID",
        "validation": {
          "required": "ID is required",
          "unique": "ID is not unique",
          "pattern": "ID must start with a letter and only contain letters, numbers, and '_'."
        },
        "count": {
          "filter": "filter",
          "condition": "condition"
        },
        "automatic": {
          "label": "Automatically update expression references",
          "empty": "No expressions need to be updated"
        }
      }
    }
  },
  "sites": "sites",
  "site": {
    "notFound": "Site ID not found",
    "title": "Kibsi Sites",
    "createSite": "Create Site",
    "timezone": "Timezone",
    "edit": {
      "title": "Edit the title or description of your site",
      "name": "Site name",
      "namePlaceholder": "Enter site name",
      "description": "Site description"
    },
    "stream": {
      "splash": {
        "mainTitle": "Get started with your new site.",
        "altImage": "Add a new stream",
        "title": "Connect at least one stream",
        "message": "When you create a site it is important to connect at least one stream. Click on the stream icon on the top right to view and create your streams after adding your first from here."
      },
      "create": {
        "button": "Create Stream",
        "title": "New stream"
      },
      "edit": {
        "data": {
          "subheader": "Name and adjust stream settings",
          "connectHeader": "Connect your stream",
          "source": {
            "label": "Source",
            "rtsp": "RTSP",
            "youtube": "YouTube",
            "stream": "Stream",
            "upload": "Upload"
          },
          "name": {
            "rule": "Stream name cannot be empty"
          },
          "url": {
            "label": "Copy your URL here",
            "rule": "URL is required"
          },
          "fps": {
            "label": "Frames per second"
          },
          "video": {
            "rule": "Please upload a video.",
            "sizeLimit": "Video size is limited to {{value}}. Please choose another video.",
            "suggested": "Your video cannot exceed {{ value }} and should end in .mov or .mp4"
          },
          "resolution": {
            "override": "Resolution Override",
            "width": "Width",
            "height": "Height",
            "heightRequired": "height required",
            "widthRequired": "width required",
            "enterNumber": "enter a number",
            "inputDescription": "Specify the exact resolution used to process the stream",
            "invalidTotalResolution": "Please reduce the total resolution",
            "unit": "px"
          }
        },
        "snapshot": {
          "change": "Change snapshot",
          "subheaderCreate": "Upload a snapshot of your stream",
          "subheaderEdit": "Upload a new snapshot of your stream"
        },
        "upload": {
          "dropHelper": "Drop your video file here, or",
          "suggested": "Your file should be a video"
        }
      },
      "missing": "This site cannot be selected because it is missing a video stream. <1>Fix this.</1>",
      "currentScreenshot": "Current screenshot",
      "updateFromStream": "Update from stream",
      "replaceImage": "Replace image",
      "replaceSnapshotTitle": "Replace {{value}} screenshot"
    },
    "change": "Change Site",
    "deployment": {
      "create": {
        "button": "Create Deployment",
        "title": "New Deployment",
        "description": "Choose from published applications"
      }
    },
    "count": "{{sites}} Sites",
    "create": {
      "title": "New Site",
      "subheader": "Enter your basic information",
      "input": {
        "timezone": "Timezone"
      },
      "rules": {
        "name": "Site name cannot be empty"
      }
    }
  },
  "model": "model",
  "models": {
    "label": "models",
    "title": "Detector Models",
    "createModel": "Create Detector Model",
    "description": {
      "placeholder": "Describe your model"
    },
    "classes": "Classes",
    "added": "Added",
    "additionalOptions": "Additional options",
    "splash": {
      "model": {
        "heading": "You don't have any models yet.",
        "altImage": "Configure Deployment",
        "title": "Create your first model.",
        "message": "Sites is where your application comes to life and where you deploy them from. Click on the home icon on the top right to view and create your deployments or add one from here.",
        "buttonTitle": "Create A Model"
      },
      "detector": {
        "heading": "Time to add some detectors.",
        "altImage": "Configure Deployment",
        "title": "Add detectors to make your model functional.",
        "message": "Your model won’t know what is important to you unless you let it know.  Add a detector for each item you are interested.",
        "buttonTitle": "Add a detector"
      }
    },
    "edit": {
      "heading": "Predefined Architecture",
      "data": {
        "title": "Define your predefined architecture model",
        "architecture": "Architecture",
        "size": "Size",
        "sizeType": {
          "nano": "Nano",
          "small": "Small",
          "medium": "Medium",
          "large": "Large"
        },
        "imageDimensions": "Image dimensions",
        "confidenceThreshold": "Confidence threshold",
        "confidenceDescription": "Confidence adjusts the sensitivity of the model.",
        "agnosticNMS": "Agnostic NMS",
        "rules": {
          "name": "Model name is required"
        }
      },
      "upload": {
        "title": "Upload your files",
        "dropHelper": "Drop your weight file here, or",
        "suggested": "Your file cannot exceed {{ value }} and should end in .npz",
        "weightsFile": "Weights file",
        "classMapping": "Class Mapping",
        "classMappingDesc": "Type your classes, each on a new line. \nOr drop your object mapping file here.",
        "modelIncludes": "Model includes {{count}} classes",
        "uploadSuccessful": "Upload successful",
        "warning": "Changes to this model will impact existing detectors, application and deployments",
        "rules": {
          "weightFileRequired": "Please select a weight file.",
          "sizeLimit": "Model size is limited to {{value}}. Please choose another file."
        }
      }
    }
  },
  "detector": {
    "label": "detector",
    "add": "Add Detector",
    "confidence": "Min. confidence",
    "enhancer": "Enhancer",
    "create": {
      "heading": "Create new detector"
    },
    "edit": {
      "heading": "Edit detector",
      "data": {
        "title": "Name your detector and select your classes",
        "existing": "Existing model classes",
        "rules": {
          "name": "Detector name is required",
          "description": "Detector description is required",
          "oneOrMore": "Select one or more classes"
        }
      },
      "mapping": {
        "title": "Choose the type and specific instances",
        "subtitle": "You will need to select a class or multiple classes from those provided by model.",
        "usage": "Usage",
        "enhancer": "Enhancer",
        "detector": "Use this detector as a detected item type",
        "compatible": {
          "enhancer": "Select any compatible enhancers",
          "detector": "Select existing detectors that can enhance this item type",
          "itemTypes": "Select compatible item types (optional)",
          "useAsEnhancer": "If this detector can be used as an enhancer attribute, please select compatible item types from the list below."
        },
        "rules": {
          "oneOrMore": {
            "detector": "Select one or more detectors",
            "enhancer": "Select one or more enhancers"
          }
        }
      }
    }
  },
  "deployments": "Deployments",
  "deploymentWithCount_one": "{{ count }} deployment",
  "deploymentWithCount_other": "{{ count }} deployments",
  "streams": "Streams",
  "itemTypes": "Item types",
  "deployment": {
    "title": "Deployment",
    "count": "{{deployments}} Deployments",
    "configure": "Configure Deployment",
    "debugger": "Debugger",
    "debuggerLaunch": "Launch Debugger",
    "missing": {
      "shortMessage": "Missing application",
      "longMessage": "Underlying application missing",
      "alert": {
        "title": "Missing application",
        "message": "You cannot configure this deployment since the underlying application has been deleted"
      }
    },
    "debuggerError": {
      "status": "Debugger status is {{status}}. Please try again shortly",
      "error": "Debugger has an error: {{msg}}. Please try again shortly",
      "notFound": "Debugger not found. Please try again shortly",
      "unknown": "Debugger has unknown error: {{msg}}. Please try again shortly"
    },
    "debuggerStatus": "Debugger status is {{status}}. Please try again shortly",
    "edit": "Edit",
    "stop": "Stop",
    "start": "Start",
    "restart": "Restart",
    "pause": "Pause",
    "resume": "Resume schedule",
    "tooltip": {
      "editDisabled": "This deployment cannot be edited or launched because the source application was deleted."
    },
    "splash": {
      "altImage": "Create Deployment",
      "mainTitle": "One more step until your site is ready for deployment.",
      "title": "Optimization incoming!",
      "message": "Add a previously created application to your site to tailor Kibsi to your business needs.",
      "create": "Connect application"
    },
    "config": {
      "title": "{{name}} setup",
      "splash": {
        "altImage": "Configure Deployment",
        "mainTitle": "Get your deployment up and running.",
        "title": "Launch your deployment by setting it up once",
        "message": "We need a couple more details from you before you can launch your deployment. Double-check your variables in the top-right menu or go straight to the deployment process from here."
      },
      "menu": {
        "application": "Application",
        "applicationName": "Application Name",
        "streams": "Streams",
        "staticItems": "Static Items",
        "detectedItems": "Detectable Items (optional)",
        "variables": "Variables"
      },
      "button": {
        "launch": "Launch",
        "schedule": "Schedule",
        "finishLater": "Finish Later",
        "nextStep": "Next Step"
      },
      "appVer": {
        "chooseVersion": "Choose a version",
        "newestVersion": "Newest version",
        "olderVersions": "Older versions",
        "title": "Updating Application Version",
        "message": {
          "staticItems": "Selecting {{versionNumber}} will remove some/all current static items.  Are you Sure?"
        }
      },
      "stream": {
        "selectStream": "Select a Stream"
      },
      "staticItems": {
        "title": "Add your static items",
        "customize": "Customize {{value}}",
        "dialog": {
          "title": "Delete {{name}}",
          "text": "You are about to delete this item. If you continue, the item and its attributes will be discarded"
        },
        "enhancer": {
          "selectRegion": "Select Region",
          "entireScreen": "Entire screen",
          "custom": "Custom"
        },
        "region": {
          "editRegion": "Edit Region",
          "showOther": "Show other regions",
          "input": {
            "rules": {
              "polygon": "Polygon must have three or more points"
            }
          }
        },
        "bulkCreate": {
          "header": "Create your static items",
          "description": "Specify the number of each static item type to create for this deployment. You can adjust this later."
        }
      },
      "detectedItems": {
        "title": "Configuration for detected items (optional)",
        "list": {
          "title": "Detected Items"
        },
        "editor": {
          "reset": "Reset to application setting",
          "detector": {
            "title": "{{name}} settings",
            "description": "Override the previously configured application settings for confidence and tracking."
          },
          "region": {
            "title": "{{name}} detection regions",
            "description": "Items are detected on the entire screen by default.",
            "entireScreen": "Entire screen",
            "custom": "Custom",
            "dialogTitle": "Detection regions"
          }
        }
      },
      "launch": {
        "title": "Launch deployment",
        "text": "You are about to launch your deployment.",
        "textDisabledVersion": "You are about to launch your deployment with a disabled version.",
        "overwritten": "If you already have a deployment on this site it will be overwritten and its data it will be erased."
      },
      "discard": {
        "title": "Discard draft setup",
        "text": "You are about to discard changes to this deployment."
      },
      "advancedOptions": "Advanced Options",
      "frameRate": {
        "title": "Frame rate",
        "description": "The frame rate is set to {{value}} frame per second by default.",
        "editDescription": "Adjust the frame rate that is set to {{value}} frames per second by default.",
        "videoOptions": "Video options"
      },
      "schedule": {
        "title": "Schedule",
        "scheduleDeployment": "Schedule deployment",
        "text": "You are about to schedule your deployment.",
        "textDisabledVersion": "You are about to schedule your deployment with a disabled version.",
        "scheduleOptions": "Schedule options",
        "description": "Define a schedule to automatically start and stop the deployment."
      },
      "template": {
        "templateOptions": "Template Options",
        "title": "Template Deployment",
        "description": "Templates do not run in Kibsi but may be instantiated on managed devices."
      }
    },
    "appVersion": "{{name}} Version {{version}}",
    "videos": "Videos",
    "images": "Images",
    "live": {
      "title": "Live View",
      "events": {
        "statistics": {
          "title": "Event Statistics",
          "reset": "Reset time and counts",
          "showIndividual": "Show Individual events",
          "hideIndividual": "Group individual events",
          "detail": {
            "general": "General",
            "count": "Collection of events",
            "instances": "Instances"
          }
        },
        "individual": {
          "title": "Individual events"
        },
        "noneActive": "No events are currently active"
      }
    },
    "history": {
      "title": "History",
      "timeline": "Timeline",
      "selectDateAndTime": "Select date and time",
      "changes": "Changes",
      "toggleCounter": "Toggle counter",
      "timerange": {
        "hour_one": "{{count}} hour",
        "hour_other": "{{count}} hours",
        "today": "Today",
        "lastDays_one": "yesterday",
        "lastDays_other": "Last {{count}} days",
        "custom": "Custom"
      },
      "items": "Items",
      "state": {
        "static": "Static",
        "general": "General",
        "attributes": "Attributes",
        "relationships": "Relationships",
        "events": "Events",
        "tracked": "Tracked attributes",
        "count": "Counter attributes"
      }
    },
    "status": {
      "reason": {
        "stopped": {
          "delete": "because the deployment is deleted",
          "eof": "because the end of the video has been reached",
          "user": "because the user has stopped the deployment",
          "limit": "because your runtime limit was reached",
          "schedule": "because of your deployment schedule"
        },
        "running": {
          "user": "because the user launched the deployment",
          "schedule": "because of your deployment schedule"
        }
      }
    },
    "limit": {
      "contact": "Please contact Kibsi if you would like to increase your limit",
      "error": "Error getting entitlements",
      "running": {
        "notFound": "Your account cannot run any deployments.",
        "header": "Running deployment limit reached",
        "info_one": "Your account reached its limit of {{count}} running deployment.",
        "info_other": "Your account reached its limit of {{count}} running deployments.",
        "helper": "Please stop another deployment before continuing."
      },
      "runtime": {
        "header": "Runtime duration limited",
        "info_one": "Your plan allows your applications to run for up to {{count}} minute.",
        "info_other": "Your plan allows your applications to run for up to {{count}} minutes."
      }
    },
    "vdb": {
      "restartRequired": {
        "title": "Restart Required",
        "tooltip": "Please restart to view live data"
      }
    }
  },
  "regions": "Regions",
  "unnamed": "unnamed",
  "videoStream": {
    "title": "Video stream",
    "showDetections": "Detections",
    "showRegions": "Regions",
    "unavailable": {
      "alert": {
        "title": "Restart Required",
        "message": "Please restart to view live video"
      }
    }
  },
  "event": {
    "justNow": "Just now",
    "since": "since {{time}}",
    "eventFootage": "Event footage",
    "downloadData": "Download data",
    "jumpToDashboard": "Jump to dashboard",
    "details": {
      "source": "Source",
      "time": "Time",
      "eventAttributes": "Event attributes",
      "sourceAttributes": "Source attributes",
      "actionTaken": "Action taken",
      "invokedWebhook": "Invoked webhook",
      "invokedEmail": "Invoked email",
      "processing": "Event details are processing. Please try again momentarily"
    },
    "media": {
      "label": "Media",
      "viewAll": "View all",
      "events": "Events",
      "triggeredActions": "Triggered Actions",
      "beginning": "Start",
      "end": "End",
      "entire": "Entire event",
      "occurance": "Occurrence",
      "interval": "Interval",
      "multiple": "Multiple",
      "webhook": "Webhook",
      "email": "Email",
      "sms": "SMS"
    },
    "data": {
      "label": "Model feedback"
    }
  },
  "stream": {
    "label": "stream",
    "noSnapshot": "No snapshot found",
    "replaceImage": "Replace image",
    "url": "URL",
    "upload": "Upload",
    "description": {
      "placeholder": "Describe your stream"
    },
    "connectivity": {
      "directToCloud": "Cloud",
      "edge": "Edge",
      "header": "Stream connectivity type"
    },
    "resolution": "Resolution",
    "dimension": "{{ width }} x {{ height }}"
  },
  "account": {
    "greeting": "Hello {{name}}"
  },
  "user": "user",
  "profile": {
    "title": "Profile",
    "nav": {
      "logout": "Log Out",
      "profile": "Profile",
      "email": "Email address",
      "password": "Password",
      "timezone": "Timezone",
      "language": "Language",
      "userAccounts": "User accounts",
      "cloudIntegrations": "Cloud Integrations"
    },
    "profile": {
      "header": "Profile",
      "email": "Email address",
      "password": "Password",
      "changePassword": "Change Password",
      "timezone": "Timezone",
      "language": "Language"
    },
    "userAccounts": {
      "header": "User accounts",
      "name": "Name",
      "accountType": "Account type",
      "created": "Created",
      "lastLogin": "Last login",
      "status": "Status",
      "enabled": "Enabled",
      "pending": "Pending",
      "invitationExpired": "Invitation Expired",
      "blocked": "Blocked",
      "disabled": "Disabled",
      "resendInvite": "(Resend Invitation)",
      "invitationResent": "(Invite sent)"
    },
    "password": {
      "header": "Change your password",
      "description": "Your password needs to have at least one uppercase letter, one number, and one special character.",
      "newPassword": {
        "label": "New password",
        "required": "Fill out this field"
      },
      "repeatPassword": {
        "label": "Repeat new password",
        "required": "Fill out this field",
        "noMatch": "Passwords don't match"
      },
      "rules": {
        "nonEmpty": "Non-empty password required",
        "lengthAtLeast": "At least {{0}} characters in length",
        "containsAtLeast": "At least {{0}} of the following {{1}} types of characters:",
        "lowerCase": "lower case letters (a-z)",
        "upperCase": "upper case letters (A-Z)",
        "numbers": "numbers (i.e. 0-9)",
        "specialCharacters": "special characters (e.g. !@#$%^&*)"
      }
    },
    "invite": {
      "header": "Invite people to the team",
      "description": "Invite people to the team by copying the link below or entering their email addresses. You can invite as many as X people to your project.",
      "email": {
        "label": "Email address",
        "required": "Fill out this field",
        "invalid": "Email format not valid"
      },
      "userName": {
        "label": "Full Name"
      },
      "addAnother": "Add another person",
      "button": "Invite"
    },
    "picture": {
      "title": "Update profile picture"
    }
  },
  "devices": "Devices",
  "device": {
    "title": "Edge Devices",
    "label": "device",
    "splash": {
      "mainTitle": "You don’t have any devices yet.",
      "altImage": "Add a new device",
      "title": "Link your first device",
      "message": "An edge device allows video processing and detections to run locally. You can link the device from here or if you don’t have one yet, contact Kibsi to learn more."
    },
    "create": {
      "button": "Link a device",
      "title": "Add a new device",
      "description": "Please check your wifi connections for the Kibsi network and type the 6-digit code following “Kibsi-” into the boxes below.",
      "enterCode": "Enter your code",
      "rules": {
        "code": "The code you entered appears to not be valid. Please try again."
      }
    },
    "edit": {
      "title": "Edit device"
    },
    "name": {
      "rule": "Device name cannot be empty"
    },
    "status": {
      "error": "Error",
      "online": "Online",
      "offline": "Offline",
      "provisioning": "Provisioning"
    }
  },
  "floorPlans": "Floor plans",
  "floorPlan": {
    "label": "floor plan",
    "image": {
      "current": "Current floor map",
      "none": "No map found",
      "replaceImageTitle": "Replace {{value}} floor map"
    },
    "upload": {
      "title": "Floor plan upload",
      "dropHelperAlt": "Drop your floor plan image here,"
    },
    "splash": {
      "heading": "You do not have any floor plans yet.",
      "altImage": "Add a new floor plan",
      "title": "Create your first floor plan.",
      "message": "Floor plans provide a top-down view of one or more streams, enabling features such as heat maps."
    },
    "create": {
      "button": "Create floor plan",
      "title": "New floor plan",
      "description": "Name and upload a floor plan image",
      "selectStreamsDescription": "You will need to add and map one or more streams to your floor plan by selecting them from the list below.",
      "chooseStream": "Choose at least one stream"
    },
    "name": {
      "rule": "Floor plan name cannot be empty"
    },
    "streamIds": {
      "rule": "You must select at least one stream"
    },
    "file": {
      "rule": "Select a floor plan to be uploaded"
    },
    "status": {
      "tooltip": "This floor plan is not set up completely. Finish mapping to use it for a heat map.",
      "complete": "Setup {{ date }}",
      "pending": "Pending setup",
      "good": "good",
      "okay": "okay",
      "short": "short"
    },
    "description": {
      "placeholder": "Describe your floor plan"
    },
    "mappingHelp": {
      "help1": "Create 4 - 10 points per camera",
      "help2": "Spread points across the camera view",
      "help3": "Place points on the ground; not on top of elevated objects"
    },
    "replacePlan": "Replace plan",
    "addExisting": "Add existing camera",
    "addStreams": "Add streams",
    "mapper": {
      "horizontal": "Horizontal",
      "vertical": "Vertical"
    },
    "stream": {
      "delete": {
        "title": "Remove {{ value }}",
        "description": "This camera has associated points. Deleting this camera means you will lose this progress. Would you like to delete the camera anyway?"
      }
    }
  },
  "dateTime": {
    "default": "{{date, datetime}}",
    "lastUpdated": "last updated {{date, datetime}}",
    "lastUpdatedTime": "last updated {{ date }} at {{ time }}",
    "published": "published {{date, datetime}}",
    "startedOn": "Started on {{date, datetime}} at {{time, datetime}}",
    "since": "Since {{date, datetime}}",
    "neverUpdated": "never updated",
    "day_one": "{{count}} day",
    "day_other": "{{count}} days",
    "dayShort": "{{count}}d",
    "hour_one": "{{count}} hour",
    "hour_other": "{{count}} hours",
    "hourShort": "{{count}}h",
    "min_one": "{{count}} min",
    "min_other": "{{count}} mins",
    "minShort": "{{count}}m",
    "sec_one": "{{count}} sec",
    "sec_other": "{{count}} secs",
    "secShort": "{{count}}s",
    "timePassed": {
      "sec": "Just now",
      "min_one": "{{count}} min ago",
      "min_other": "{{count}} mins ago",
      "hour_one": "{{count}} hour ago",
      "hour_other": "{{count}} hours ago",
      "day_one": "Yesterday",
      "day_other": "{{count}} days ago"
    },
    "day": {
      "short": {
        "0": "Sun",
        "1": "Mon",
        "2": "Tue",
        "3": "Wed",
        "4": "Thu",
        "5": "Fri",
        "6": "Sat"
      }
    },
    "am": "AM",
    "pm": "PM"
  },
  "expression": {
    "name": "Expression name",
    "title": "Expression",
    "advanced": "KEL",
    "javascript": "JavaScript",
    "unitRendering": {
      "duration": "{{value}}{{unit}}"
    },
    "save": "Save expression",
    "suggestions": "Suggestions"
  },
  "delay": {
    "label": "Delay",
    "description": "Expression is delayed to stabilize by a number of seconds"
  },
  "duration": {
    "label": "Duration"
  },
  "threshold": {
    "label": "Threshold"
  },
  "validation": {
    "nameAlreadyExists": "Name {{name}} already exists!"
  },
  "file": {
    "browse": "browse",
    "replace": "replace",
    "dropHelper": "Drop your image here, or",
    "dropHelperAlt": "Or drop your image here, or",
    "suggested": "Suggested JPEG, PNG",
    "uploadAnother": "Upload another one",
    "uploadSuccessful": "Upload successful"
  },
  "playground": {
    "title": "Kibsi Playground",
    "auth": {
      "title": "Kibsi Auth"
    },
    "expression": {
      "title": "Kibsi Expression"
    },
    "polygon": {
      "title": "Kibsi Polygon"
    },
    "stateMachine": {
      "title": "Kibsi State Machine"
    },
    "form": {
      "title": "Kibsi Forms"
    }
  },
  "unit": {
    "second": {
      "short": "s",
      "medium": "sec",
      "long_one": "second",
      "long_other": "seconds"
    },
    "minute": {
      "short": "m",
      "medium": "min",
      "long_one": "minute",
      "long_other": "minutes"
    },
    "hour": {
      "short": "h",
      "medium": "hour",
      "long_one": "hour",
      "long_other": "hours"
    }
  },
  "count": {
    "current": "Current",
    "unique": "Unique",
    "min": "Min.",
    "max": "Max.",
    "countMin": "Count min",
    "countMax": "Count max",
    "minimum": "Minimum",
    "maximum": "Maximum"
  },
  "tracked": {
    "title": "Track status changes",
    "info": "Track status changes",
    "description": "Tracking enabled show the first, current and last values of your attribute.",
    "first": "First",
    "last": "Last",
    "previous": "Previous",
    "current": "Current",
    "atStart": "Start",
    "atEnd": "End",
    "firstValue": "First value",
    "lastValue": "Last value",
    "currentValue": "Current value",
    "previousValue": "Previous value"
  },
  "snapshot": {
    "column": {
      "id": "ID",
      "time": "Time",
      "sourceId": "Source ID",
      "sourceType": "Source Type",
      "startTime": "Start Time",
      "endTime": "End Time"
    }
  },
  "items": "Items",
  "attributes": "Attributes",
  "relationships": "Relationships",
  "proximityRelationships": "Proximity relationships",
  "unknown": "Unknown",
  "support": {
    "getHelp": "Get help",
    "weAreHere": "We are here in case you need us"
  },
  "error": {
    "errorMessages": "Error messages",
    "sorry": "Sorry... something went wrong.",
    "message": "Error: {{value}}",
    "code": "Code",
    "url": "URL",
    "requestId": "Request ID",
    "emptyValue": "Value cannot be empty",
    "emptyStringValue": "{{ value }} cannot be empty",
    "addOneOrMore": "Add one or more items."
  },
  "search": {
    "splash": "No {{ items }} were found that match your search.",
    "noMatches": "No matches"
  },
  "option": {
    "toggle": {
      "auto": "Auto",
      "custom": "Custom",
      "default": "Default",
      "off": "Off",
      "on": "On",
      "true": "True",
      "false": "False",
      "undefined": "Undefined"
    },
    "additional": {
      "title_zero": "Additional options",
      "title": "Additional options ({{count}})"
    }
  },
  "filter": {
    "label": "Filter",
    "new": "New filter",
    "apply": "Apply a filter to any item type",
    "=": "equal to",
    "!=": "not equal to",
    ">": "greater than",
    ">=": "greater than or equal to",
    "<": "less than",
    "<=": "less than or equal to",
    "~": "contains",
    "isTrue": "is true",
    "isFalse": "is false",
    "delete": {
      "title": "Delete filter",
      "message": "This will delete the filter and cannot be undone"
    }
  },
  "cloudIntegrations": "Cloud integrations",
  "cloudIntegration": {
    "label": "cloud integration",
    "splash": {
      "heading": "No cloud profiles are associated with this account.",
      "altImage": "integration",
      "title": "Create a cloud profile.",
      "message": "Kibsi currently supports AWS. In the near future you will also be able to connect to Azure and GCP."
    },
    "integrations": {
      "header": "Cloud integrations",
      "name": "Profile name",
      "provider": "Provider",
      "destination": "Destination"
    },
    "create": {
      "button": "Create a profile",
      "title": "Set up a cloud profile",
      "description": "Use a cloud profile to send your deployment data into your target environment."
    },
    "edit": {
      "title": "Edit a cloud profile"
    },
    "name": {
      "rule": "Cloud integration name cannot be empty"
    },
    "provider": {
      "label": "Provider",
      "helperText": "Choose a provider",
      "aws": "AWS",
      "azure": "Azure (coming soon)",
      "gcp": "GCP (coming soon)"
    },
    "roleArn": {
      "label": "Role ARN",
      "placeHolderText": "Paste your role ARN here",
      "rule": "The AWS Role ARN cannot be empty"
    },
    "destination": {
      "label": "Destination",
      "Kinesis": "Kinesis Data Streams",
      "Firehose": "Kinesis Firehose",
      "SQS": "SQS"
    },
    "cft": {
      "launch": "Launch Stack",
      "helperText": "This button will open in a new window. Following the prompts on the AWS website and return here to paste your Role ARN and stream name below."
    },
    "queueUrl": {
      "label": "Queue Url",
      "placeHolderText": "Paste your SQS queue url here",
      "rule": "The SQS queue url cannot be empty"
    },
    "streamName": {
      "label": "Stream Name",
      "placeHolderText": "Paste your stream name here",
      "rule": "The Kinesis stream name cannot be empty"
    },
    "deliveryStreamName": {
      "label": "Delivery Stream Name",
      "placeHolderText": "Paste your delivery stream name here",
      "rule": "The Firehose delivery stream name cannot be empty"
    },
    "deployment": {
      "storageOptions": "Storage options",
      "storageHeader": "Cloud Integration for data storage",
      "storageDescription": "Choose a cloud profile from the list below that you would like to use as data storage.",
      "existingProfiles": "Existing profiles"
    }
  },
  "heatmapLegend": {
    "colorSettings": "Color settings",
    "linear": "Linear",
    "logarithmic": "Logarithmic",
    "interval": "Interval",
    "hue": "Hue",
    "hueOffset": "Hue offset",
    "satOffset": "Saturation offset"
  },
  "scheduler": {
    "label": "Scheduler",
    "repeat": "Repeat",
    "daily": "Daily",
    "weekly": "Weekly",
    "start": "Start",
    "stop": "Stop",
    "nextDay": "Next day",
    "nextWeek": "Next week",
    "theNextDay": "the next day",
    "theNextWeek": "the next week",
    "dailyDescription": "Deployment will start {{day}} at {{startTime}} and stop {{stopTime}} {{nextDay}}",
    "weeklyDescription": "Deployment will start on {{startDay}} at {{startTime}} and stop {{stopDay}} at {{stopTime}} {{nextWeek}}",
    "scheduleDateTime": "will {{ action }} {{ date }} at {{ time }}"
  },
  "tag": {
    "loading": "loading...",
    "add": "Add",
    "addTags": "Add tags",
    "addNew": "Add a new tag"
  },
  "sort": {
    "sortBy": "Sort by {{ value }}",
    "modified": "Modified",
    "name": "Name",
    "published": "Published",
    "deployed": "Deployed"
  },
  "debugger": {
    "appState": "App State",
    "detections": "Detections"
  }
}
