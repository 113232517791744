import { ForwardedRef, forwardRef, FunctionComponent, MouseEvent, ReactElement, ReactNode } from 'react'
import { Box, Stack, SvgIconProps, SxProps } from '@mui/material'
import { lighten, useTheme } from '@mui/material/styles'
import { asSxArray } from '../../../utils/mui'
import { useDisabledOpacity } from '../../../hooks'

export type KBOptionButtonVariant = 'standard' | 'filled'

export type KBOptionButtonProps = {
    color?: string
    variant?: KBOptionButtonVariant
    active?: boolean
    icon?: FunctionComponent<SvgIconProps>
    onClick?: (event: MouseEvent<HTMLDivElement>) => void
    children?: ReactNode
    shadows?: boolean
    size?: 'medium' | 'small'
    sx?: SxProps
    disabled?: boolean
    'data-testid'?: string
}

export const KBOptionButton = forwardRef(function KBOptionButton<T>(
    {
        active = false,
        children,
        color,
        variant = 'standard',
        icon: Icon,
        sx,
        disabled,
        shadows = false,
        size = 'medium',
        'data-testid': dataTestId,
        onClick,
    }: KBOptionButtonProps,
    ref: ForwardedRef<T>,
): ReactElement {
    const theme = useTheme()

    const disabledOpacity = useDisabledOpacity(disabled)

    const primaryColor = color || theme.palette.primary.main
    const defaultColor = active ? primaryColor : 'text.secondary'
    const activeColor = lighten(primaryColor, variant === 'standard' ? 0.9 : 0)
    const textColor = variant === 'standard' ? primaryColor : theme.palette.primary.contrastText

    const defaultTextColor = active ? textColor : defaultColor
    const defaultBackgroundColor = active ? activeColor : null
    const hoverTextColor = !disabled || active ? textColor : defaultColor
    const hoverBackgroundColor = !disabled && activeColor

    const getPadding = (): string => {
        switch (size) {
            case 'small':
                return '2px 6px 2px 6px'
            case 'medium':
                return '5px 10px 5px 10px'
            default:
                return ''
        }
    }

    const styles = {
        container: [
            {
                fontSize: '13px',
                padding: getPadding(),
                borderRadius: '3px',
                height: 1,
                boxShadow: shadows && !active ? 1 : 0,
                backgroundColor: defaultBackgroundColor,
                color: defaultTextColor,
                '&:hover': {
                    cursor: disabled ? 'default' : 'pointer',
                    backgroundColor: hoverBackgroundColor,
                    color: hoverTextColor,
                },
            },
            ...asSxArray(sx),
        ],
    }

    return (
        <Box
            onClick={(e) => {
                if (disabled) {
                    return
                }
                onClick?.(e)
            }}
            aria-disabled={disabled}
            aria-pressed={active}
            data-testid={dataTestId}
            sx={{ height: 1, opacity: disabledOpacity }}
            ref={ref}
        >
            <Stack direction="row" alignItems="center" sx={styles.container}>
                {Icon && <Icon sx={{ mr: children ? 1 : 0, fontSize: '16px' }} />}
                {children && (
                    <Box sx={{ fontSize: '13px', whiteSpace: 'nowrap', typography: 'subtitle2' }}>{children}</Box>
                )}
            </Stack>
        </Box>
    )
})
