import { DetectorDef, Duration } from './generated/types'

export function durationToMilliseconds(delay?: Duration): number {
    if (!delay) {
        return 0
    }

    const conversions = {
        S: 1000,
        M: 1000 * 60,
        H: 1000 * 60 * 60,
    }

    return delay.value * conversions[delay.unit]
}

export function detectorDefToIds(def: DetectorDef | DetectorDef[] | undefined): string[] {
    if (!def) {
        return []
    }

    return [def].flat().map((detectorDef) => detectorDef.detectorId)
}
