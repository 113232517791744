import { useCallback } from 'react'
import T from 'config/inversify.types'
import logger from 'logging/logger'
import { useInjection } from 'inversify-react'
import { AuthConfig } from 'auth/auth.config'
import { useAuth0 } from '@auth0/auth0-react'

export const useLogin = (authTime?: number, flow?: string): (() => Promise<void>) => {
    const { loginWithRedirect } = useAuth0()
    const config = useInjection<AuthConfig>(T.AuthConfig)
    const login = useCallback(async () => {
        // need to send the user to the login
        // the user may be authenticated, but for another tenant
        // or the user isn't authenticated at all

        const { hostname, pathname, protocol, search } = window.location

        if (config.domain && config.domain !== hostname) {
            // at the wrong site based on auth check
            window.location.assign(`${protocol}//${config.domain}${pathname}`)
            return
        }

        const returnTo = `${pathname ?? '/'}${search}`
        logger.info(`upon successful login,  will redirect to ${returnTo}`)

        await loginWithRedirect({
            appState: {
                returnTo,
                flow,
            },
            authorizationParams: {
                max_age: authTime ? 0 : undefined,
                // note: this should be picked up by the setting in KPAuthProvider, but when logging in right
                // after the auth/check call it's not always set.
                organization: config.org,
            },
        })
    }, [authTime, config, flow, loginWithRedirect])
    return login
}
