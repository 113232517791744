import {
    DownloadUrl,
    FloorPlan,
    FloorPlanCreate,
    FloorPlanCriteria,
    FloorPlanListResponse,
    FloorPlanUpdate,
    UploadUrl,
} from '@kibsi/ks-tenant-types'
import { AxiosRequestConfig } from 'axios'
import { PaginationRequest } from 'types/pagination'
import { KibsiClient, KibsiClientParams } from './base'

const SERVICE = 'tenant'

export class FloorPlanKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, SERVICE)
    }

    list(
        paginationRequest?: PaginationRequest<FloorPlanCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<FloorPlanListResponse> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { floorPlanIds, siteId } = criteria ?? {}

        return this.get('/floor-plans', {
            ...requestConfig,
            ...{ params: { continuationToken, pageSize, sort, floorPlanIds: floorPlanIds?.join(','), siteId } },
        })
    }

    read(entityId: string, requestConfig?: AxiosRequestConfig): Promise<FloorPlan> {
        return this.get(`/floor-plans/${entityId}`, requestConfig)
    }

    create(entity: FloorPlanCreate, requestConfig?: AxiosRequestConfig): Promise<FloorPlan> {
        return this.post('/floor-plans', entity, requestConfig)
    }

    update(entity: FloorPlanUpdate, requestConfig?: AxiosRequestConfig): Promise<FloorPlan> {
        return this.put(`/floor-plans/${entity.floorPlanId}`, entity, requestConfig)
    }

    delete(id: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/floor-plans/${id}`, requestConfig)
    }

    getImageUploadUrl(streamId: string, contentType: string, requestConfig?: AxiosRequestConfig): Promise<UploadUrl> {
        return this.put(`/floor-plans/${streamId}/image`, null, { ...requestConfig, params: { contentType } })
    }

    getImageDownloadUrl(streamId: string, requestConfig?: AxiosRequestConfig): Promise<DownloadUrl> {
        return this.get(`/floor-plans/${streamId}/image`, requestConfig)
    }
}
