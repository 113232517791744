import type { EventEmitter2 } from 'eventemitter2'
import { io, Socket } from 'socket.io-client'
import msgpack from 'socket.io-msgpack-parser'
import { reaction, IReactionDisposer, makeObservable, observable, action } from 'mobx'
import { DeploymentStatus } from '@kibsi/ks-deployment-types'
import log from 'logging/logger'

import { Deployment } from '../deployment'

export class VdbClient {
    private reactions: IReactionDisposer[] = []
    private namespaces = new Map<string, Socket>()
    private socket?: Socket
    private version?: number
    private connected = false

    constructor(private deployment: Deployment, private appbus: EventEmitter2) {
        makeObservable<this, 'version' | 'connected'>(this, {
            version: observable,
            connected: observable,
        })
    }

    start(): void {
        this.reactions.push(reaction(() => this.status, this.onStatus.bind(this), { fireImmediately: true }))
    }

    stop(): void {
        this.reactions.forEach((r) => r())
        this.disconnect()
    }

    async namespace(ns: string): Promise<void> {
        await this.createNamespace(ns)
    }

    get id(): string {
        return this.deployment.deploymentId
    }

    get isConnected(): boolean {
        return this.connected
    }

    get isLiveVideoSupported(): boolean {
        return this.version === undefined || this.version >= 2
    }

    get isLiveDataSupported(): boolean {
        return this.version === undefined || this.version >= 1
    }

    private get status(): DeploymentStatus {
        return this.deployment.deploymentStatus
    }

    private onStatus(status: DeploymentStatus): void {
        switch (status) {
            case 'EOF':
            case 'Running':
                this.connect().catch((e) => {
                    log.error('vdb connection error', e)
                    this.disconnect()
                })
                break
            default:
                this.disconnect()
                break
        }
    }

    private async connect(): Promise<void> {
        if (!this.socket) {
            this.socket = await this.createSocket()
        }
    }

    private disconnect(): void {
        Array.from(this.namespaces.entries()).forEach(([key, value]) => {
            this.appbus.removeAllListeners(getPrefix(this.id, key))
            value.disconnect()
        })
        this.namespaces.clear()
        this.socket = undefined
        this.version = undefined
    }

    private async createSocket(): Promise<Socket> {
        const socket = await this.createNamespace()

        socket.on(
            'version',
            action((value: number) => {
                this.version = value
            }),
        )

        socket.on(
            'disconnect',
            action(() => {
                this.connected = false
            }),
        )

        socket.on(
            'connect',
            action(() => {
                this.connected = true

                setTimeout(
                    action(() => {
                        if (this.version === undefined) {
                            this.version = 0
                        }
                    }),
                    1_000,
                )
            }),
        )

        return socket
    }

    private async createNamespace(ns = '/'): Promise<Socket> {
        if (this.namespaces.has(ns)) {
            return this.namespaces.get(ns) as Socket
        }

        const endpoint = await this.deployment.loadVdbEndpoint()

        const socket = io(`https://${endpoint.hostname}${ns}?_t=${endpoint.token}`, {
            transports: ['websocket'],
            parser: msgpack,
            reconnectionDelayMax: 30_000,
        })

        const prefix = getPrefix(this.id, ns)

        socket.on('connect_error', (e) => {
            this.appbus.emit(`${prefix}/connect_error`, e)
        })

        socket.on('disconnect', (reason) => {
            this.appbus.emit(`${prefix}/disconnect`, reason)
        })

        socket.on('connect', () => {
            this.appbus.emit(`${prefix}/connect`)
        })

        socket.onAny((name: string, payload: unknown) => {
            this.appbus.emit(`${prefix}/${name}`, payload)
        })

        this.appbus.on(prefix, (name: string, payload: unknown) => {
            if (typeof name === 'string') {
                log.debug(`send to ${prefix} - ${name}`, payload)
                socket.emit(name, payload)
            }
        })

        this.namespaces.set(ns, socket)

        return socket
    }
}

function getPrefix(id: string, ns: string) {
    const isMain = ns === '/'
    return `${id}/vdb${isMain ? '' : ns}`
}
