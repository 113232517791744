import type { AxiosError } from 'axios'
import type { Logger } from './logger'

export interface ErrorHandlerContext {
    logger?: Logger
}

export interface ErrorHandler {
    (err: AxiosError, ctx: ErrorHandlerContext): never
}
