import { KBLoading, KBTooltip } from '@kibsi/ui-components'
import { FlagOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export type FlagButtonProps = {
    flagged?: boolean
    dense?: boolean
    loading?: boolean
    onChange?: (flagged: boolean) => void
}

export function FlagButton({ flagged = false, dense, loading, onChange }: FlagButtonProps): ReactElement {
    const { t } = useTranslation()
    return loading ? (
        <KBLoading loading size={dense ? '10px' : '16px'} sx={{ m: 1, justifyContent: 'flex-end' }} />
    ) : (
        <KBTooltip title={flagged ? t('action.removeFlag') : t('action.addFlag')}>
            <IconButton
                size="small"
                onClick={(e) => {
                    e.stopPropagation()
                    onChange?.(!flagged)
                }}
            >
                <FlagOutlined
                    sx={{ color: flagged ? 'error.main' : 'action.active', fontSize: dense ? '18px' : '24px' }}
                />
            </IconButton>
        </KBTooltip>
    )
}
