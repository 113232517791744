import { AxiosRequestConfig } from 'axios'
import type { DeploymentARTSnapshot, Subscription, SubscriptionCreate } from '@kibsi/ks-realtime-data-types'
import { KibsiClient, KibsiClientParams } from './base'
import type { Endpoint } from '../types'

const SERVICE = 'realtime-data'

export class RealtimeDataKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, SERVICE)
    }

    createSubscription(subscription: SubscriptionCreate, requestConfig?: AxiosRequestConfig): Promise<Subscription> {
        return this.post(`subscriptions`, subscription, requestConfig)
    }

    endpoint(requestConfig?: AxiosRequestConfig): Promise<Endpoint> {
        return this.get(`/ws/endpoint`, requestConfig)
    }

    deploymentSnapshot(
        deploymentId: string,
        itemType?: string,
        requestConfig?: AxiosRequestConfig,
    ): Promise<DeploymentARTSnapshot> {
        return this.get(`deployments/${deploymentId}/snapshot`, {
            ...requestConfig,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            params: {
                ...requestConfig?.params,
                itemType,
            },
        })
    }
}
