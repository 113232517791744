import { AuthCheckResponse, PasswordPolicyResponse, Tenant, TenantCreate, TenantUpdate } from '@kibsi/ks-tenant-types'
import { AxiosRequestConfig } from 'axios'
import { KibsiClient, KibsiClientParams } from './base'

const SERVICE = 'tenant'

export class TenantKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, SERVICE)
    }

    authCheck(requestConfig?: AxiosRequestConfig): Promise<AuthCheckResponse> {
        return this.post(`/auth/check`, requestConfig)
    }

    read(requestConfig?: AxiosRequestConfig): Promise<Tenant> {
        return this.get(`/tenants/self`, requestConfig)
    }

    create(entity: TenantCreate, requestConfig?: AxiosRequestConfig): Promise<Tenant> {
        return this.post('/tenants', entity, requestConfig)
    }

    update(entity: TenantUpdate, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/tenants/self`, entity, requestConfig)
    }

    delete(requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/tenants/self`, requestConfig)
    }

    passwordPolicy(requestConfig?: AxiosRequestConfig): Promise<PasswordPolicyResponse> {
        return this.get(`/tenants/self/passwordPolicy`, requestConfig)
    }

    claimMarketplace(marketplaceToken: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.post(`/tenants/self/claim-marketplace`, undefined, {
            ...requestConfig,
            params: { marketplaceToken },
        })
    }
}
