import { AttributeRelationTypeDef, AttributeTypeDef } from '../generated/types'

import { isRelationshipAttr } from './relationship.guard'

/** Attribute narrowed to expression relationship */
export type ExpressionRelationshipAttr = Omit<AttributeTypeDef, 'value'> & {
    value: AttributeRelationTypeDef
}

export function isExpressionRelationshipAttr(attr?: AttributeTypeDef): attr is ExpressionRelationshipAttr {
    return isRelationshipAttr(attr) && attr.value.relation.relationType === 'expression'
}
