import {
    Dashboard,
    DashboardCreate,
    DashboardCriteria,
    DashboardListResponse,
    DashboardUpdate,
    DashboardUrl,
} from '@kibsi/ks-tenant-types'
import { AxiosRequestConfig } from 'axios'
import { PaginationRequest } from 'types/pagination'
import { KibsiClient, KibsiClientParams } from './base'

const SERVICE = 'tenant'

export class DashboardKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, SERVICE)
    }

    list(
        paginationRequest?: PaginationRequest<DashboardCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<DashboardListResponse> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { dashboardIds, siteId } = criteria ?? {}

        return this.get('/dashboards', {
            ...requestConfig,
            ...{ params: { continuationToken, pageSize, sort, dashboardIds: dashboardIds?.join(','), siteId } },
        })
    }

    read(entityId: string, requestConfig?: AxiosRequestConfig): Promise<Dashboard> {
        return this.get(`/dashboards/${entityId}`, requestConfig)
    }

    create(entity: DashboardCreate, requestConfig?: AxiosRequestConfig): Promise<Dashboard> {
        return this.post('/dashboards', entity, requestConfig)
    }

    update(entity: DashboardUpdate, requestConfig?: AxiosRequestConfig): Promise<Dashboard> {
        return this.put(`/dashboards/${entity.dashboardId}`, entity, requestConfig)
    }

    delete(id: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/dashboards/${id}`, requestConfig)
    }

    getDashboardUrl(entityId: string, requestConfig?: AxiosRequestConfig): Promise<DashboardUrl> {
        return this.get(`/dashboards/${entityId}/url`, requestConfig)
    }
}
