export type SortDirection = 'asc' | 'desc'

export interface SortBy {
    direction: SortDirection
    column: string
}

export interface PaginationRequest<Criteria = unknown> {
    /**
     * https://kibsi.atlassian.net/wiki/spaces/KIBSI/pages/64126977/Pagination
     */
    continuationToken?: string

    /**
     * https://kibsi.atlassian.net/wiki/spaces/KIBSI/pages/66715649/Sorting
     */
    sort?: string[]

    /**
     * Page size
     */
    pageSize?: number

    /**
     * Search criteria
     */
    criteria?: Criteria
}

export interface PaginationResponse<Entity> {
    results: Entity[]
    /**
     * estimated or actual count of available results
     */
    totalResults?: number

    /**
     * https://kibsi.atlassian.net/wiki/spaces/KIBSI/pages/64126977/Pagination
     */
    nextContinuationToken?: string
}
