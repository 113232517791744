import { Site, SiteCreate, SiteCriteria, SiteUpdate } from '@kibsi/ks-tenant-types'
import { AxiosRequestConfig } from 'axios'
import { PaginationRequest, PaginationResponse } from 'types/pagination'
import { KibsiClient, KibsiClientParams } from './base'

const SERVICE = 'tenant'

export class SiteKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, SERVICE)
    }

    list(
        paginationRequest?: PaginationRequest<SiteCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Site>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { siteIds, includeCounts } = criteria ?? {}

        return this.get('/sites', {
            ...requestConfig,
            ...{ params: { continuationToken, pageSize, sort, siteIds: siteIds?.join(','), includeCounts } },
        })
    }

    read(entityId: string, requestConfig?: AxiosRequestConfig): Promise<Site> {
        return this.get(`/sites/${entityId}`, requestConfig)
    }

    create(entity: SiteCreate, requestConfig?: AxiosRequestConfig): Promise<Site> {
        return this.post('/sites', entity, requestConfig)
    }

    update(entity: SiteUpdate, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/sites/${entity.siteId}`, entity, requestConfig)
    }

    delete(siteId: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/sites/${siteId}`, requestConfig)
    }
}
