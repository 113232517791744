import { AxiosRequestConfig } from 'axios'
import { ClaimDeviceReq, Device, DeviceCriteria, DeviceUpdate } from '@kibsi/ks-tenant-types'
import { PaginationRequest, PaginationResponse } from 'types/pagination'
import { KibsiClient, KibsiClientParams } from './base'

const SERVICE = 'tenant'

export class EdgeDeviceKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, SERVICE)
    }

    list(
        paginationRequest?: PaginationRequest<DeviceCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Device>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { ids, siteId } = criteria ?? {}
        return this.get('/devices', {
            ...(paginationRequest && {
                params: { continuationToken, pageSize, sort, ids: ids?.join(','), siteId },
            }),
            ...requestConfig,
        })
    }

    claimDevice(payload: ClaimDeviceReq, requestConfig?: AxiosRequestConfig): Promise<Device> {
        return this.post('/devices', payload, requestConfig)
    }

    update(payload: DeviceUpdate, requestConfig?: AxiosRequestConfig): Promise<Device> {
        const { id } = payload
        return this.patch(`/devices/${id}`, payload, requestConfig)
    }
}
