import { EventCriteria, KibsiEvent, KibsiEventDetailed, MediaDownload, MediaMetadata } from '@kibsi/ks-event-types'
import { AxiosRequestConfig } from 'axios'
import { PaginationRequest, PaginationResponse } from 'types/pagination'
import { KibsiClient, KibsiClientParams } from './base'

export class EventKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, 'event')
    }

    listEvents(
        paginationRequest?: PaginationRequest<EventCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<KibsiEvent>> {
        const { continuationToken, pageSize, sort, criteria = {} } = paginationRequest || {}
        const { active, deploymentId, applicationId, siteId, itemType, startDateTime, endDateTime, dataCollection } =
            criteria
        const request: AxiosRequestConfig = requestConfig ? { ...requestConfig } : {}

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        request.params = {
            ...requestConfig?.params,
            continuationToken,
            pageSize,
            sort,
            deploymentId,
            active,
            applicationId,
            siteId,
            itemType,
            startDateTime,
            endDateTime,
            dataCollection,
        }

        return this.get('/events', request)
    }

    getEvent(deploymentId: string, eventId: string, requestConfig?: AxiosRequestConfig): Promise<KibsiEventDetailed> {
        return this.get(`events/${deploymentId}/${eventId}`, requestConfig)
    }

    getEventMediaMetadata(
        deploymentId: string,
        eventId: string,
        requestConfig?: AxiosRequestConfig,
    ): Promise<MediaMetadata[]> {
        return this.get(`events/${deploymentId}/${eventId}/media`, requestConfig)
    }

    getMediaDownload(mediaId: string, requestConfig?: AxiosRequestConfig): Promise<MediaDownload> {
        return this.get(`media/${mediaId}/download`, requestConfig)
    }

    setEventFlagged(
        deploymentId: string,
        eventId: string,
        flagged: boolean,
        requestConfig?: AxiosRequestConfig,
    ): Promise<void> {
        return this.put(`events/${deploymentId}/${eventId}/flag`, { flagged }, requestConfig)
    }
}
