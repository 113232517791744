import {
    AttributeCountTypeDef,
    AttributeRegionTypeDef,
    AttributeRelationTypeDef,
    AttributeSimpleType,
    AttributeStateMachineTypeDef,
    AttributeTypeDef,
    AttributeExpressionTypeDef,
} from './generated/types'

export type NarrowedAttr<T extends AttributeTypeDef['value']> = Omit<AttributeTypeDef, 'value'> & {
    value: T
}

/** Attribute narrowed to thier value types */
export type RegionAttr = NarrowedAttr<AttributeRegionTypeDef>
export type SimpleAttr = NarrowedAttr<AttributeSimpleType>
export type RelationshipAttr = NarrowedAttr<AttributeRelationTypeDef>
export type StateMachineAttr = NarrowedAttr<AttributeStateMachineTypeDef>
export type ExpressionAttr = NarrowedAttr<AttributeExpressionTypeDef>
export type CountAttr = NarrowedAttr<AttributeCountTypeDef>
