import { makeAutoObservable } from 'mobx'
import { EventType, EventItemType, ItemType } from '@kibsi/ks-application-types'
import { KibsiEvent } from '@kibsi/ks-event-types'
import { EventService } from 'service'
import Logger from 'logging/logger'

import { ArtItemState, ArtSnapshot } from '../realtime-data'
import { Deployment, DeploymentUIConfig } from '../deployment'
import { Event } from './event'
import { endTimeSort, startTimeSort, activeThenStartTimeSort } from './sorting'

const MaxActive = 5
const MaxRecent = 10

const log = Logger.getLogger('EventGroup')

export interface IEventGroup {
    id: string
    name: string
    startDate: Date | undefined
    counter: number
    recent: Event[]
    isSpan: boolean
}

export class EventGroup implements IEventGroup {
    private items: Map<string, Event> = new Map()
    private count = 0
    private startTs?: number

    constructor(
        readonly itemType: EventItemType,
        private deployment: Deployment,
        private eventService: EventService,
        private config: DeploymentUIConfig,
        private snapshot: ArtSnapshot,
    ) {
        snapshot.onItemAdded(itemType.id, this.onItemAdded.bind(this))

        makeAutoObservable(this)
    }

    get id(): string {
        return this.itemType.id
    }

    get name(): string {
        return this.itemType.name
    }

    /**
     * Events that are displayed in the grid
     */
    get events(): Event[] {
        return this.getEvents(MaxActive)
    }

    /**
     * For the details panel. Includes move events than active.
     */
    get recent(): Event[] {
        return this.getEvents(MaxRecent).sort(activeThenStartTimeSort)
    }

    get color(): string {
        return this.config.getEventTypeColor(this.itemType.id)
    }

    get counter(): number {
        return this.count
    }

    get isGrouped(): boolean {
        return this.isOccurrence || this.config.isEventTypeHideIndividual(this.itemType.id)
    }

    get isSpan(): boolean {
        return this.eventType === 'span'
    }

    get isOccurrence(): boolean {
        return this.eventType === 'occurrence'
    }

    get startDate(): Date | undefined {
        return this.startTs ? new Date(this.startTs) : undefined
    }

    get sourceItemTypeId(): string | undefined {
        return this.itemType.event.sourceItemId
    }

    get sourceItemType(): ItemType | undefined {
        const { sourceItemTypeId } = this

        if (!sourceItemTypeId) {
            return undefined
        }

        return this.deployment.getItemTypeById(sourceItemTypeId)
    }

    reset(): void {
        const { active } = this

        this.startTs = undefined

        active.forEach((e) => {
            const start = e.startDate.valueOf()

            if (this.startTs === undefined || this.startTs > start) {
                this.startTs = start
            }
        })

        if (active.length === 0) {
            this.startTs = Date.now()
        }

        this.count = active.length
    }

    setupEvents(events: KibsiEvent[]): void {
        this.items.clear()

        events
            .filter((e) => e.itemType === this.itemType.id)
            .forEach((e) => {
                this.addEvent(e)
            })

        this.reset()
    }

    private get active(): Event[] {
        return this.values.filter((e) => e.isActive)
    }

    private get values(): Event[] {
        return Array.from(this.items.values())
    }

    private get eventType(): EventType | undefined {
        return this.itemType.event.eventType
    }

    private addEvent(dto: KibsiEvent): void {
        if (this.items.has(dto.id)) {
            log.trace(`Existing event [${this.itemType.id}] `, dto.id)
            return
        }

        const event = new Event(dto, this.deployment, this.eventService)

        this.snapshot.onItemUpdated(this.itemType.id, event.id, this.onItemUpdated.bind(this))

        this.items.set(event.id, event)
        this.count++
    }

    private getEvents(min: number) {
        const items = this.values
        const events = items.filter((e) => e.isVisible)
        const numShort = min - events.length

        if (numShort > 0) {
            items
                .filter((e) => !e.isVisible)
                .sort(endTimeSort)
                .slice(0, numShort)
                .forEach((e) => events.push(e))
        }

        return events
    }

    private prune(): void {
        const overflow = this.items.size - MaxRecent

        if (overflow <= 0) {
            return
        }

        const events = this.values.filter((e) => !e.isVisible).sort(startTimeSort)

        log.trace(`Removing ${overflow} events from ${this.id}`)

        events.slice(-overflow).forEach((e) => {
            this.items.delete(e.id)
        })
    }

    private onItemAdded(item: ArtItemState): void {
        log.trace(`onItemAdded [${this.itemType.id}] `, item)

        const dto = this.createEventDto(item)
        this.addEvent(dto)
        this.prune()
    }

    private onItemUpdated(item: ArtItemState): void {
        log.trace(`onItemUpdated [${this.itemType.id}]`, item)

        const dto = this.createEventDto(item)
        this.items.get(item.state.id)?.fromDto(dto)
    }

    private createEventDto({ state }: ArtItemState): KibsiEvent & Record<string, unknown> {
        const source = state.source ?? state.snapshot

        return {
            deploymentId: this.deployment.deploymentId,
            id: state.id,
            itemType: this.itemType.id,
            sourceId: state.sourceId as string,
            sourceType: state.sourceType as string,
            endSourceState: typeof source === 'string' ? JSON.parse(source) : undefined,
            startTime: state.startTime as number,
            endTime: state.endTime as number,
            flagged: false,
        }
    }
}
