import { Primitives } from 'models'

export const normalizeAttributeValue = (attrValue: Primitives): Primitives => {
    try {
        if (typeof attrValue === 'string') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return JSON.parse(attrValue)
        }
        return attrValue
    } catch (e) {
        return attrValue
    }
}
