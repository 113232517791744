import { Menu, MenuProps } from '@mui/material'
import { PropsWithChildren, ReactElement, RefObject } from 'react'

interface KBContextMenuProps {
    open: boolean
    anchorRef: RefObject<HTMLButtonElement>
    onMenuClose: () => void
}

export function KBContextMenu({
    anchorRef,
    children,
    onMenuClose,
    ...menuProps
}: PropsWithChildren<KBContextMenuProps> & MenuProps): ReactElement {
    return (
        <Menu
            anchorEl={anchorRef.current}
            onClose={onMenuClose}
            onClick={onMenuClose}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            {...menuProps}
        >
            {children}
        </Menu>
    )
}
