import { AxiosRequestConfig } from 'axios'
import { KibsiClient } from './base'

/**
 * Client to make API calls to Kibsi services.
 * Implement per entity type by defining the REST paths. Or don't, up to you.
 */
export abstract class KibsiCrudClient extends KibsiClient {
    /** CRUD patterns */

    abstract list(parentId: string, requestConfig?: AxiosRequestConfig): Promise<unknown[]>
    abstract create(entity: unknown, requestConfig?: AxiosRequestConfig): Promise<unknown>
    abstract read(entityId: string, requestConfig?: AxiosRequestConfig): Promise<unknown>
    abstract update(entity: unknown, requestConfig?: AxiosRequestConfig): Promise<void>
    abstract delete(entity: unknown, requestConfig?: AxiosRequestConfig): Promise<void>
}
