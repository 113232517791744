import { Tag } from 'model/tag'
import { CommonCriteria } from './common.search'

export function commonSearchDtoFactory(q?: string, tags?: Tag[]): CommonCriteria | undefined {
    if (!q && !tags) {
        return undefined
    }

    return {
        q,
        tagIds: tags && tags.length > 0 ? tags.map((t) => t.tagId) : undefined,
    }
}
