import { Stack, StackProps, SxProps, Typography } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { sxs } from '../../utils/mui'
import { KBSidePanelDivider } from './KBSidePanelDivider'

export type KBPanelSectionProps = {
    title?: string
    divider?: boolean
    action?: ReactNode
    titleSx?: SxProps
} & StackProps

export function KBPanelSection({
    title,
    titleSx,
    divider = true,
    action,
    children,
    ...props
}: KBPanelSectionProps): ReactElement {
    return (
        <>
            {divider && <KBSidePanelDivider />}
            <Stack px={2} {...props}>
                {title && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={sxs({ py: 0.75 }, titleSx)}
                    >
                        <Typography variant="subtitle2">{title}</Typography>
                        {action}
                    </Stack>
                )}
                <Stack>{children}</Stack>
            </Stack>
        </>
    )
}
