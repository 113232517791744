import { AxiosRequestConfig } from 'axios'
import { KibsiClient, KibsiClientParams } from './base'

interface TimeData {
    time: string
}

export class TimeKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, 'tenant')
    }

    async getTime(requestConfig?: AxiosRequestConfig): Promise<string> {
        const result = await this.get<TimeData>('time', requestConfig)
        return result.time
    }
}
